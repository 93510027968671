import adminDivisions from '../../constants/country-admin-divisions';
import { getCountriesToCheck } from './countries-to-check';
import { buildOtherNameVersions } from './name-versions';

// Map of all available sources (both functions and constants)
const SOURCES = {
  adminDivisions,
  buildOtherNameVersions,
  getCountriesToCheck,
  // Add other sources here
};

const processOptions = (optionsConfig, values) => {
  if (!optionsConfig) return [];

  // If it's an array, use it directly
  if (Array.isArray(optionsConfig)) {
    return optionsConfig;
  }

  // If it's a string, check if it's a direct function first
  if (typeof optionsConfig === 'string') {
    // Check if it's a direct function in SOURCES
    if (SOURCES[optionsConfig]) {
      const source = SOURCES[optionsConfig];
      if (typeof source === 'function') {
        return source(values);
      }
      return source || [];
    }

    // Otherwise treat it as a path
    const [sourceName, ...path] = optionsConfig.split('.');
    const source = SOURCES[sourceName];
    if (!source) return [];

    if (typeof source === 'function') {
      return source(values);
    }

    return path.reduce((obj, key) => obj?.[key], source) || [];
  }

  return [];
};

const applyConfig = (field, config, values) => {
  Object.entries(config).forEach(([key, value]) => {
    if (key === 'options') {
      field.options = processOptions(value, values);
    } else if (key === 'processor') {
      // If processor is provided as a string directly
      const processorFn = SOURCES[value];
      if (typeof processorFn === 'function') {
        field.value = processorFn(values, config.processor_params);
      }
    } else {
      field[key] = value;
    }
  });
};

const getSourceValue = (values, dependsOn, parentField, arrayIndex) => {
  // If we're in an array context, first try to get the value from the same array index
  if (parentField && (parentField.type === 'dynamic_array' || parentField.type === 'array')) {
    const arrayValue = values?.[parentField.code]?.[arrayIndex]?.[dependsOn];
    if (arrayValue !== undefined) {
      return arrayValue;
    }
  }

  // If not found in array or not in array context, try direct form value
  return values?.[dependsOn];
};

export const processDynamicContent = (field, values, context = {}) => {
  const { parentField, arrayIndex = 0 } = context;

  if (!field.frontend_data_config) {
    return field;
  }

  const config = field.frontend_data_config;

  if (!config.depends_on) {
    applyConfig(field, config, values);
    return field;
  }

  if (!config.configurations) {
    return field;
  }

  const sourceValue = getSourceValue(values, config.depends_on, parentField, arrayIndex);

  const configuration =
    sourceValue !== undefined
      ? config.configurations[sourceValue] || config.configurations.default
      : config.configurations.default;

  if (configuration) {
    applyConfig(field, configuration, values);
  }

  return field;
};
