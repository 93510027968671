import { useEffect, useState } from 'react';

import cn from 'classnames';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import styles from './Checkbox.module.scss';

export const Checkbox = ({
  name,
  value,
  checked,
  label,
  tooltip,
  defaultChecked = false,
  validators = {},
  register = () => {},
  className,
  style,
  onClick = () => {},
}) => {
  const [isChecked, setIsChecked] = useState(checked ?? defaultChecked ?? false);

  useEffect(() => {
    if (checked !== undefined) {
      setIsChecked(checked);
    }
  }, [checked]);

  const handleClick = () => {
    onClick(!isChecked);
    setIsChecked(!isChecked);
  };

  return (
    <div
      style={style}
      className={cn(
        'd-flex',
        'align-items-center',
        'u-padding-bottom--small',
        'u-padding-top--small',
        { [className]: className },
      )}>
      <input
        type="checkbox"
        name={name}
        defaultChecked={defaultChecked}
        className={cn(isChecked ? styles.checkboxActive : styles.checkbox)}
        value={value}
        onClick={handleClick}
        {...register(name, {
          ...validators,
          onChange: e => setIsChecked(e.target.checked),
        })}
      />
      <span className="t-small u-padding-left--small text-color-primary">{label}</span>
      {tooltip && (
        <OverlayTrigger placement="bottom" overlay={<Tooltip>{tooltip}</Tooltip>}>
          <span className={styles.tooltip}>
            <FeatherIcon icon="info" />
          </span>
        </OverlayTrigger>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  checked: PropTypes.bool,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  defaultChecked: PropTypes.bool,
  validators: PropTypes.object,
  register: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
