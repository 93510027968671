const patternValidators = {
  // Text Validators
  alphanumeric: {
    pattern: {
      value: /^[A-Za-z0-9]*$/,
      message: 'This field can only contain letters and numbers.',
    },
  },

  name: {
    pattern: {
      value: /^[A-Za-z][A-Za-z' -]*[A-Za-z]$/,
      message:
        'The name must start and end with a letter and can only include letters, hyphens, apostrophes, and spaces.',
    },
  },

  // Number Validators
  positiveIntegerOnly: {
    pattern: {
      value: /^\d+$/,
      message: 'Only positive whole numbers are allowed.',
    },
  },

  // Email Validator (improved pattern)
  email: {
    pattern: {
      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      message: 'Please enter a valid email address.',
    },
  },

  // Website Validator
  website: {
    pattern: {
      value: /^(https?:\/\/)?([\w-]+(\.[\w-]+)+\/?)([\w-._~:/?#[\]@!$&'()*+,;=]*)?$/,
      message: 'Please enter a valid website URL (e.g., www.example.com or https://example.com)',
    },
  },

  // ID Number Validators
  ssn: {
    pattern: {
      value: /^(?!666|000|9\d{2})\d{3}[-]?(?!00)\d{2}[-]?(?!0000)\d{4}$/,
      message: 'Enter a valid Social Security Number (XXX-XX-XXXX or XXXXXXXXX).',
    },
  },

  nzIrdNumber: {
    pattern: {
      value: /^(?:\d{2}-?\d{3}-?\d{3}|\d{3}-?\d{3}-?\d{3})$/,
      message: 'Enter a valid New Zealand IRD Number (9 digits).',
    },
  },

  usLicenseValidator: {
    pattern: {
      value: /^[A-Za-z0-9-]+$/,
      message: 'Only letters (A-Z, a-z), numbers (0-9), and hyphens (-) are allowed.',
    },
  },

  // Check Specific Validators
  au_wwcc_NswNumber: {
    pattern: {
      value: /^(WWC\d{7}[EV]?|APP\d{7})$/,
      message:
        "WWCC number must start with 'WWC' or 'APP', followed by 7 digits, with an optional 'E' or 'V'.",
    },
  },
  au_wwcc_VicNumber: {
    pattern: {
      value: /^\d{7}[A-Za-z0-9]$/,
      message: 'WWCC number must be 8 characters long, with the first 7 as numbers.',
    },
  },
  au_wwcc_QldNumber: {
    pattern: {
      value: /^\d{1,7}\/\d{1,2}$/,
      message: "Card number must have 1-7 digits, a '/', and 1-2 digits after.",
    },
  },
  au_wwcc_WaNumber: {
    pattern: {
      value: /^\d{1,32}$/,
      message: 'The number must only include digits.',
    },
  },
};

export default patternValidators;
