import { useEffect } from 'react';

import autosize from 'autosize';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './Textarea.module.scss';

export const Textarea = ({
  name,
  autoComplete,
  placeholder,
  defaultValue,
  minLength,
  maxLength,
  validators = {},
  dynamicHeight,
  register = () => {},
  error = '',
  noErrorMessage,
  className,
  inputProps = {},
}) => {
  useEffect(() => {
    if (dynamicHeight) {
      autosize(document.querySelectorAll('.' + className));
    }
  }, [dynamicHeight]);

  return (
    <>
      <textarea
        name={name}
        autoComplete={autoComplete}
        placeholder={placeholder}
        defaultValue={defaultValue}
        minLength={minLength}
        maxLength={maxLength}
        {...register(name, validators)}
        className={cn(styles.root, {
          [className]: className,
          [styles.invalid]: !!error,
        })}
        {...inputProps}
      />
      {error && !noErrorMessage && <span className={styles.error}>{error}</span>}
    </>
  );
};

Textarea.propTypes = {
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  validators: PropTypes.object,
  dynamicHeight: PropTypes.bool,
  register: PropTypes.func,
  error: PropTypes.string,
  noErrorMessage: PropTypes.bool,
  className: PropTypes.string,
  inputProps: PropTypes.object,
};
