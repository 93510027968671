import { Alpha3Countries } from '../../constants/countries';
import adminDivisions from '../../constants/country-admin-divisions';

// Map of available constants
const CONSTANTS_MAP = {
  adminDivisions,
  Alpha3Countries,
};

/**
 * Process a path to get a value from a constant
 * Example: "adminDivisions.AU_STATES.VIC.name" -> "Victoria"
 */
const processPath = (path, constants) => {
  const parts = path.split('.');
  let value = constants[parts[0]];

  for (let i = 1; i < parts.length; i++) {
    if (!value) return null;

    // If this part contains a lookup value (e.g., "AU_STATES[VIC]")
    if (parts[i].includes('[')) {
      const [arrayName, lookup] = parts[i].split('[');
      const lookupValue = lookup.replace(']', '');
      value = value[arrayName]?.find(item => item.value === lookupValue);
      continue;
    }

    value = value[parts[i]];
  }

  return value;
};

/**
 * Replaces template variables in strings with their actual values from the constants map.
 * Example template: {{adminDivisions.AU_STATES[VIC].name}}
 */
export const replaceConstantTemplates = text => {
  if (typeof text !== 'string' || !text.includes('{{')) return text;

  // Create a string of all constant source names from CONSTANTS_MAP joined by '|' (OR operator)
  const sources = Object.keys(CONSTANTS_MAP).join('|');
  const pattern = new RegExp(`{{((?:${sources})(?:\\.[a-zA-Z0-9_\\[\\]]+)*?)}}`, 'g');

  return text.replace(pattern, (match, path) => {
    try {
      const value = processPath(path, CONSTANTS_MAP);
      return value ?? match;
    } catch (error) {
      return match;
    }
  });
};

export const processConstantTemplates = field => {
  if (!field) return field;

  if (typeof field === 'object') {
    return Object.entries(field).reduce(
      (acc, [key, value]) => {
        if (typeof value === 'string') {
          const processed = replaceConstantTemplates(value);
          acc[key] = processed;
        } else if (Array.isArray(value)) {
          acc[key] = value.map(item => processConstantTemplates(item));
        } else if (typeof value === 'object') {
          acc[key] = processConstantTemplates(value);
        } else {
          acc[key] = value;
        }
        return acc;
      },
      Array.isArray(field) ? [] : {},
    );
  }
  return field;
};
