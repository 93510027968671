export const resolveFieldValue = (fieldCode, formValues, context = {}) => {
  const { parentField, arrayIndex } = context;

  // If in array context, try to get value from same array item first
  if (parentField && Array.isArray(formValues[parentField.code])) {
    const arrayValue = formValues[parentField.code][arrayIndex]?.[fieldCode];
    if (arrayValue !== undefined) {
      return arrayValue;
    }
  }

  // Fallback to direct form value
  return formValues[fieldCode];
};
