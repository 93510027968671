import { idTypes } from '../../constants/id-types';
import evaluateFieldConditions from '../evaluate-field-conditions';

export const processSelectedIds = (idFieldCodes, values, fields) => {
  if (!idFieldCodes || !values || !fields) return {};
  const types = {};

  // Helper function to find field config by code
  const findFieldConfig = code => {
    const findInFields = fieldsArray => {
      for (const field of fieldsArray) {
        if (field.code === code) return field;
        if (field.sub_fields) {
          const found = findInFields(field.sub_fields);
          if (found) return found;
        }
      }
      return null;
    };
    return findInFields(fields);
  };

  idFieldCodes.forEach(fieldCode => {
    const fieldConfig = findFieldConfig(fieldCode);
    if (!fieldConfig) return;

    // Check if field should be visible based on conditions
    if (fieldConfig.conditions) {
      const isVisible = evaluateFieldConditions(fieldConfig.conditions, values, { fields });
      if (!isVisible) return;
    }

    const fieldValue = values?.[fieldCode];

    // Handle checkbox values (both true and false)
    if (typeof fieldValue === 'boolean') {
      if (fieldValue === true) {
        const [type, countries] = fieldCode.split(':');
        if (!type) return;

        if (!types[type]) {
          types[type] = new Set();
        }

        if (countries) {
          countries.split(',').forEach(country => types[type].add(country));
        }
      }
      return;
    }

    // Handle existing radio/select/multi-select cases
    if (!fieldValue || fieldValue.startsWith('no_')) return;

    // Handle both single and multi-select values
    const selectedValues = Array.isArray(fieldValue) ? fieldValue : [fieldValue];

    selectedValues.forEach(selectedValue => {
      if (!selectedValue || typeof selectedValue === 'boolean') return;

      const [type, countries] = selectedValue.split(':');
      if (!type) return;

      if (!types[type]) {
        types[type] = new Set();
      }

      if (countries) {
        countries.split(',').forEach(country => types[type].add(country));
      }
    });
  });

  return Object.fromEntries(
    Object.entries(types).map(([type, data]) => [
      type,
      data instanceof Set ? Array.from(data).sort() : data,
    ]),
  );
};

const processRadioButtonOptions = options => {
  return [
    {
      name: `I have a ${options[0].name}`,
      value: options[0].value,
    },
    {
      name: `I do not have a ${options[0].name}`,
      value: 'no',
    },
  ];
};

const determineRequirementType = config => {
  if (
    Array.isArray(config.options) &&
    config.options.some(opt => typeof opt === 'object' && opt.options)
  ) {
    return 'complex';
  }

  // Check for points in options or min_points requirement
  const hasPoints =
    config.min_points ||
    (Array.isArray(config.options) &&
      config.options.some(opt => typeof opt === 'object' && opt.points));

  if (hasPoints) {
    return 'multi_select';
  }

  // If no points involved, use original logic
  const min = config.min || 1;
  const optionCount = Array.isArray(config.options) ? config.options.length : 1;

  if (optionCount === min) {
    return 'radio_button';
  } else if (min > 1) {
    return 'multi_select';
  } else {
    return 'select';
  }
};

const resolveIdTypeOption = option => {
  if (idTypes[option]) {
    if (Array.isArray(idTypes[option])) {
      return [...idTypes[option]];
    }

    return { ...idTypes[option] };
  }

  // If not found, return a basic option object
  return { name: option, value: option };
};

const processOptions = options => {
  // Convert single string to array for consistent processing
  const optionsArray = typeof options === 'string' ? [options] : options;

  const processedOptions = optionsArray.map(option => {
    if (typeof option === 'string') {
      const resolved = resolveIdTypeOption(option);
      return Array.isArray(resolved) ? resolved : [resolved];
    }

    if (typeof option === 'object' && option.option) {
      const processedOption = resolveIdTypeOption(option.option);
      return {
        ...processedOption,
        name:
          processedOption.name +
          (option.additional_instructions ? ` ${option.additional_instructions}` : '') +
          (option.points ? ` - ${option.points} points` : ''),
        points: option.points,
      };
    }

    if (typeof option === 'object' && option.name && option.value) {
      return {
        ...option,
        name:
          option.name +
          (option.additional_instructions ? ` ${option.additional_instructions}` : '') +
          (option.points ? ` - ${option.points} points` : ''),
        points: option.points,
      };
    }

    return option;
  });

  // Flatten the results to remove nested arrays
  return processedOptions.flat();
};

const processIdConfig = (rawConfig, code) => {
  const options = processOptions(rawConfig.options);
  let configWithOptions = {
    ...rawConfig,
    options,
  };
  const type = determineRequirementType(configWithOptions);

  if (type === 'radio_button') {
    configWithOptions = {
      ...configWithOptions,
      title: `Please confirm whether you have a ${configWithOptions.options[0].name}`,
      options: processRadioButtonOptions(configWithOptions.options, code),
    };
  }

  return {
    code,
    type,
    ...configWithOptions,
  };
};

const extractDefaultIdTypes = defaultLogic => {
  if (!defaultLogic) return {};
  const types = {};

  const extractIds = logic => {
    if (typeof logic === 'string') {
      const resolved = resolveIdTypeOption(logic);
      const [type, country] = resolved.value.split(':');
      if (!types[type]) types[type] = new Set();
      if (country) types[type].add(country);
      return;
    }

    if (Array.isArray(logic)) {
      logic.forEach(id => extractIds(id));
      return;
    }

    if (logic.op && logic.ids) {
      logic.ids.forEach(id => extractIds(id));
      return;
    }
  };

  extractIds(defaultLogic);

  // Convert Sets to sorted arrays
  return Object.fromEntries(
    Object.entries(types).map(([type, data]) => [
      type,
      data instanceof Set ? Array.from(data).sort() : data,
    ]),
  );
};

// Update processIdRequirementConfigs to only process defaults
export const processIdRequirementConfigs = configs => {
  const requirements = [];
  const defaultIdTypes = {};

  configs
    .filter(config => typeof config === 'object' && config.options)
    .map((config, index) => {
      if (config.default_logic) {
        const defaults = extractDefaultIdTypes(config.default_logic);
        // Merge defaults into defaultIdTypes
        Object.entries(defaults).forEach(([type, countries]) => {
          if (!defaultIdTypes[type]) defaultIdTypes[type] = new Set();
          countries.forEach(country => defaultIdTypes[type].add(country));
        });
      }

      const code = `id_requirement_${index}`;
      let processedConfig;

      if (
        Array.isArray(config.options) &&
        config.options.some(opt => typeof opt === 'object' && opt.options)
      ) {
        const options = config.options.map((opt, index) => {
          const childCode = `${code}_${index}`;
          return processIdConfig(opt, childCode);
        });
        processedConfig = {
          code,
          type: 'complex',
          ...config,
          options,
        };
      } else {
        processedConfig = processIdConfig(config, code);
      }

      requirements.push(processedConfig);
    });

  // Convert Sets to sorted arrays
  const processedDefaultTypes = Object.fromEntries(
    Object.entries(defaultIdTypes).map(([type, data]) => [
      type,
      data instanceof Set ? Array.from(data).sort() : data,
    ]),
  );

  // Initialize idUploadOptions with formatted default types
  const initialUploadOptions = combineIdTypes(processedDefaultTypes, {});

  return {
    requirements,
    defaultIdTypes: processedDefaultTypes,
    idUploadOptions: initialUploadOptions,
  };
};

const combineIdTypes = (defaultTypes = {}, selectedTypes = {}) => {
  const combined = {};

  // Combine all types and their countries
  [...Object.entries(defaultTypes || {}), ...Object.entries(selectedTypes || {})].forEach(
    ([type, countries]) => {
      if (!combined[type]) combined[type] = new Set();
      countries.forEach(country => combined[type].add(country));
    },
  );

  // Convert to array format
  return Object.entries(combined)
    .flatMap(([type, countries]) => {
      const countryArray = Array.from(countries).sort();
      return countryArray.length > 0 ? countryArray.map(country => `${type}:${country}`) : [type];
    })
    .sort();
};

export { combineIdTypes };
