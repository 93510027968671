import moment from 'moment';

import { resolveFieldValue } from '../candidate_application/field-value-resolver';
import { compareDates, validateDateRangeCoverage } from './date-validation-utils';

const functionValidators = {
  dateAfter: {
    validate: (value, params, formValues, fieldPath) => {
      if (!value || !params?.compareToField) return true;

      // Create context based on field path
      const context = {};
      const pathParts = fieldPath.split('.');

      if (pathParts.length === 3) {
        // We're in an array (e.g., "academic_qualifications.0.qualification_end_date")
        context.parentField = { code: pathParts[0] };
        context.arrayIndex = parseInt(pathParts[1]);
      }

      const compareValue = resolveFieldValue(params.compareToField, formValues, context);

      return compareDates(value, compareValue, 'after');
    },
  },

  addressHistory: {
    validate: (value, params, formValues) => {
      // Get current address from date
      if (!formValues.current_address_residence_from) {
        return 'Current address from date is required';
      }

      const currentAddressFrom = moment(formValues.current_address_residence_from, 'DD/MM/YYYY');
      if (!currentAddressFrom.isValid()) {
        return 'Current address from date is invalid';
      }

      // Calculate minimum past date (default 10 years)
      const yearsToCheck = params?.requiredAddressHistory || 10;
      const minPastDate = moment().subtract(yearsToCheck, 'years');

      // If current address covers entire period, we're done
      if (currentAddressFrom.isSameOrBefore(minPastDate)) {
        return true;
      }

      // Create current address range (from start date to now)
      const currentAddressRange = {
        from: currentAddressFrom,
        to: moment(),
      };

      // If we're here, we need to check previous addresses
      if (!Array.isArray(value) || value.length === 0) {
        return `Previous addresses are required to cover the last ${yearsToCheck} years`;
      }

      // Convert previous addresses to date ranges and include current address
      const dateRanges = [
        currentAddressRange,
        ...value.map(address => ({
          from: moment(address.previous_address_residence_from, 'DD/MM/YYYY'),
          to: moment(address.previous_address_residence_to, 'DD/MM/YYYY'),
        })),
      ];

      const result = validateDateRangeCoverage({
        startDate: moment(),
        minPastDate,
        dateRanges,
        toleranceMonths: params?.toleranceMonths,
      });
      return result.isValid || result.error;
    },
  },

  addressesMustInclCountry: {
    validate: (_, params, formValues) => {
      const requiredCountry = params?.mustIncludeCountry;
      if (!requiredCountry) {
        return true;
      }

      if (formValues.residential_address?.country === requiredCountry) {
        return true;
      }

      if (formValues.postal_address?.country === requiredCountry) {
        return true;
      }

      const previousAddresses = formValues.previous_addresses;
      if (Array.isArray(previousAddresses)) {
        const hasRequiredCountry = previousAddresses.some(
          address => address.previous_address?.country === requiredCountry,
        );

        if (hasRequiredCountry) {
          return true;
        }
      }

      const errorMessage = `Your address history must include a ${requiredCountry} address`;
      return errorMessage;
    },
  },
};

export default functionValidators;
