import moment from 'moment';

export const parseDateValue = value => {
  if (value === null) return null;

  // If it's a number or numeric string (whole number check)
  if (!isNaN(value) && Number.isInteger(Number(value))) {
    return { type: 'months', value: Number(value) };
  }

  // Try to parse as date string (DD/MM/YYYY)
  const dateValue = moment(value, 'DD/MM/YYYY', true);
  if (dateValue.isValid()) {
    return { type: 'date', value: dateValue };
  }

  return null;
};

export const formatDurationFromMonths = months => {
  if (months === null) return '';

  const years = Math.floor(Math.abs(months) / 12);
  const remainingMonths = Math.abs(months) % 12;

  if (years === 0) {
    return `${remainingMonths} ${remainingMonths === 1 ? 'month' : 'months'}`;
  }
  if (remainingMonths === 0) {
    return `${years} ${years === 1 ? 'year' : 'years'}`;
  }
  return `${years} ${years === 1 ? 'year' : 'years'} and ${remainingMonths} ${remainingMonths === 1 ? 'month' : 'months'}`;
};

export const constructDateMessage = (min, max) => {
  const minAmount =
    min?.type === 'months'
      ? formatDurationFromMonths(min.value)
      : min?.type === 'date'
        ? min.value.format('DD/MM/YYYY')
        : null;

  const maxAmount =
    max?.type === 'months'
      ? formatDurationFromMonths(max.value)
      : max?.type === 'date'
        ? max.value.format('DD/MM/YYYY')
        : null;

  const base = 'The date must be ';

  // Handle range cases (when both min and max exist)
  if (min && max) {
    // For month-based ranges
    if (min.type === 'months' && max.type === 'months') {
      // Special case for today only
      if (min.value === 0 && max.value === 0) {
        return base + 'today';
      }

      // Both in past
      if (min.value < 0 && max.value < 0) {
        return base + `between ${maxAmount} and ${minAmount} in the past`;
      }

      // Both in future
      if (min.value > 0 && max.value > 0) {
        return base + `between ${minAmount} and ${maxAmount} in the future`;
      }

      // From past to today
      if (min.value < 0 && max.value === 0) {
        return base + `within the last ${minAmount}`;
      }

      // From today to future
      if (min.value === 0 && max.value > 0) {
        return base + `within the next ${maxAmount}`;
      }

      // Spans past and future
      if (min.value < 0 && max.value > 0) {
        return base + `within ${maxAmount} in the future and ${minAmount} in the past`;
      }
    }

    // For exact dates or mixed types
    return base + `between ${minAmount} and ${maxAmount}`;
  }

  // Handle single boundary cases
  if (min) {
    if (min.type === 'months') {
      if (min.value === 0) return base + 'in the future';
      if (min.value > 0) return base + `more than ${minAmount} in the future`;
      if (min.value < 0) return base + `more than ${minAmount} in the past`;
    }
    return base + `after ${minAmount}`;
  }

  if (max) {
    if (max.type === 'months') {
      if (max.value === 0) return base + 'in the past';
      if (max.value < 0) return base + `more than ${maxAmount} in the past`;
      if (max.value > 0) return base + `no more than ${maxAmount} in the future`;
    }
    return base + `before ${maxAmount}`;
  }

  return true;
};

export const compareDates = (date1, date2, comparison = 'after') => {
  const moment1 = moment(date1, 'DD/MM/YYYY');
  const moment2 = moment(date2, 'DD/MM/YYYY');

  if (!moment1.isValid() || !moment2.isValid()) return true;

  switch (comparison) {
    case 'after':
      return moment1.isAfter(moment2) || `Date must be after ${date2}`;
    case 'before':
      return moment1.isBefore(moment2) || `Date must be before ${date2}`;
  }
};

export const validateDateRangeCoverage = ({
  startDate,
  minPastDate,
  dateRanges,
  toleranceMonths = 1,
}) => {
  // Input validation
  if (!startDate || !minPastDate || !Array.isArray(dateRanges)) {
    return {
      isValid: false,
      error: 'Missing required parameters',
    };
  }

  // Ensure minimum tolerance of 1 month
  const adjustedTolerance = Math.max(1, toleranceMonths);

  // Clone input dates and filter out invalid ranges
  const validatedStartDate = moment(startDate).startOf('month');
  const validatedMinPastDate = moment(minPastDate).startOf('month');

  const sortedRanges = [...dateRanges]
    .filter(range => range.from && range.to) // Ensure both from and to are present
    .map(range => ({
      from: moment(range.from).startOf('month'), // Standardize all dates to 1st of month for comparison
      to: moment(range.to).startOf('month'),
    }))
    .sort((a, b) => b.to.valueOf() - a.to.valueOf()); // Sort newest to oldest by end date

  if (sortedRanges.length === 0) {
    return {
      isValid: false,
      error: 'No valid date ranges provided',
    };
  }

  // Check that most recent range ends at validatedStartDate
  const mostRecentRange = sortedRanges[0];
  if (!mostRecentRange.to.isSame(validatedStartDate)) {
    return {
      isValid: false,
      error: `The most recent address must continue to the present date`,
    };
  }

  // Check for overlaps and gaps
  // Process each pair of adjacent ranges newest to oldest
  for (let i = 0; i < sortedRanges.length - 1; i++) {
    const currentRange = sortedRanges[i];
    const nextRange = sortedRanges[i + 1];

    // Ensure each range's date to comes after its date from
    if (currentRange.to.isBefore(currentRange.from)) {
      return {
        isValid: false,
        error: `Invalid date range: end date (${currentRange.to.format('MM/YYYY')}) is before start date (${currentRange.from.format('MM/YYYY')})`,
      };
    }

    // Ensure ranges do not overlap
    if (currentRange.from.isBefore(nextRange.to)) {
      return {
        isValid: false,
        error: `Overlapping date ranges detected between ${nextRange.to.format('MM/YYYY')} and ${currentRange.from.format('MM/YYYY')}`,
      };
    }

    // Check for gap between ranges - if the gap is greater than the tolerance, return an error
    const gap = currentRange.from.diff(nextRange.to, 'months');
    if (gap > adjustedTolerance) {
      return {
        isValid: false,
        error: `Gap of ${gap} months detected between ${nextRange.to.format('MM/YYYY')} and ${currentRange.from.format('MM/YYYY')}`,
      };
    }
  }

  // Get the oldest range and check coverage
  const lastRange = sortedRanges[sortedRanges.length - 1];
  if (lastRange.from.isAfter(validatedMinPastDate)) {
    return {
      isValid: false,
      error: `Please provide details of your full address history to at least ${minPastDate.format('MM/YYYY')}`,
    };
  }

  return { isValid: true };
};
