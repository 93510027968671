import { Input, Toggle } from '..';

import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import { debounce } from '../../../utils/requestHelpers';
import styles from './GoogleAddress.module.scss';

export const GoogleAddressInput = ({
  name,
  register,
  setValue,
  onManualEntry,
  onAddressSelect,
  hasManualEntry = false,
  validators = {},
  country = 'AU',
}) => {
  const containerRef = useRef(null);
  const [sessionToken, setSessionToken] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    const initializeSessionToken = async () => {
      const { AutocompleteSessionToken } = await google.maps.importLibrary('places');
      setSessionToken(new AutocompleteSessionToken());
    };
    initializeSessionToken();
  }, []);

  useEffect(() => {
    if (hasManualEntry) {
      setValue('manualAddressEntry', true, { shouldValidate: false });
      setValue(name, '', { shouldValidate: false });
    }
  }, [hasManualEntry]);

  const debouncedAddressSearch = debounce(async searchTerm => {
    if (!searchTerm) {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }

    const { AutocompleteSuggestion } = await google.maps.importLibrary('places');

    const request = {
      input: searchTerm,
      sessionToken: sessionToken,
      region: country,
    };

    try {
      const { suggestions } = await AutocompleteSuggestion.fetchAutocompleteSuggestions(request);
      if (suggestions.length > 0) {
        const formattedSuggestions = suggestions.map(suggestion => ({
          formattedAddress: suggestion.placePrediction.text.text,
          placeId: suggestion.placePrediction.placeId,
          placePrediction: suggestion.placePrediction,
        }));
        setSuggestions(formattedSuggestions);
        setShowSuggestions(true);
      } else {
        setSuggestions([]);
        setShowSuggestions(true);
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
      setShowSuggestions(true);
    }
  });

  const handleAddressSearch = e => {
    debouncedAddressSearch(e.target.value);
  };

  const handleSuggestionClick = async suggestion => {
    const place = suggestion.placePrediction.toPlace();
    const placeFields = ['addressComponents', 'adrFormatAddress', 'formattedAddress', 'id'];

    try {
      await place.fetchFields({
        fields: placeFields,
        sessionToken: sessionToken,
      });
      const placeDetails = place.toJSON({ fields: placeFields });

      setValue(name, placeDetails.formattedAddress, {
        shouldValidate: false,
      });

      setShowSuggestions(false);
      setValue('manualAddressEntry', false, { shouldValidate: false });
      onAddressSelect(placeDetails);
    } catch (error) {
      console.error('Error fetching place details:', error);
    }
  };

  const handleManualEntryToggle = e => {
    onManualEntry(e);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={containerRef} className={styles.searchContainer}>
      <Input
        name={name}
        type="text"
        placeholder="Start typing to search for an address..."
        register={register}
        validators={validators}
        inputProps={{
          onChange: handleAddressSearch,
        }}
      />
      {showSuggestions && suggestions.length > 0 && (
        <ul
          className={cn(
            'u-drop-shadow--large u-border-radius u-border absolute top-full left-0 w-full z-10',
            styles.suggestionsList,
          )}>
          {suggestions.map(suggestion => (
            <li
              key={suggestion.placeId}
              className={styles.suggestionItem}
              onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion.formattedAddress}
            </li>
          ))}
        </ul>
      )}
      <div className="u-flex-box u-flex-justify-start u-align-items-center u-margin-top--small">
        <p>{hasManualEntry}</p>
        <Toggle
          name="manualAddressEntry"
          className="u-margin-right--small"
          register={register}
          value={hasManualEntry}
          inputProps={{
            onChange: handleManualEntryToggle,
            checked: hasManualEntry,
          }}
        />
        <span className="secondary-text t-body">Enter address manually</span>
      </div>
    </div>
  );
};
