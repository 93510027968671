import cn from 'classnames';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import styles from './PhoneNumberPicker.module.scss';

export const PhoneNumberPicker = ({
  value,
  autoComplete,
  placeholder = 'Enter phone number',
  defaultCountry = 'NZ',
  className,
  tabIndex = -1,
  onChange = () => {},
  error,
  noErrorMessage = false,
}) => (
  <>
    <PhoneInput
      value={value}
      autoComplete={autoComplete} //new
      placeholder={placeholder}
      defaultCountry={defaultCountry}
      limitMaxLength={true}
      tabIndex={tabIndex}
      className={cn(styles.root, { [styles.rootInvalid]: !!error, [className]: className })}
      onChange={onChange}
    />
    {error && !noErrorMessage && <span className={styles.error}>{error}</span>}
  </>
);

PhoneNumberPicker.propTypes = {
  value: PropTypes.string,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  defaultCountry: PropTypes.string,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onChange: PropTypes.func,
  error: PropTypes.string,
  noErrorMessage: PropTypes.bool,
};
