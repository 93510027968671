/**
 * Validator Configuration Generator
 *
 * This module provides utilities for generating validator configurations compatible with React Hook Form.
 * It combines different types of validations:
 * 1. Required field validation
 * 2. Min/Max validations (for numbers, dates, and text lengths)
 * 3. Pattern-based validations (regex patterns from pattern-validators.js)
 * 4. Function-based validations (complex validation logic from function-validators.js)
 */
import moment from 'moment';

import { constructDateMessage, parseDateValue } from './date-validation-utils';
import functionValidators from './function-validators';
import patternValidators from './pattern-validators';

const parseNumericValue = value => {
  if (value === null) return null;
  const number = Number(value);
  return !isNaN(number) ? number : null;
};

const applyMinMaxValidators = (type, min, max) => {
  switch (type) {
    case 'date':
    case 'date_selector':
    case 'date_selector_no_day':
      const parsedDateMin = parseDateValue(min);
      const parsedDateMax = parseDateValue(max);

      return {
        validate: {
          dateRange: value => {
            if (!value) return true;
            const date = moment(value, 'DD/MM/YYYY');
            const today = moment();
            const minDate = parsedDateMin
              ? parsedDateMin.type === 'months'
                ? today.clone().add(parsedDateMin.value, 'months')
                : parsedDateMin.value
              : null;
            const maxDate = parsedDateMax
              ? parsedDateMax.type === 'months'
                ? today.clone().add(parsedDateMax.value, 'months')
                : parsedDateMax.value
              : null;

            if ((minDate && date.isBefore(minDate)) || (maxDate && date.isAfter(maxDate))) {
              return constructDateMessage(parsedDateMin, parsedDateMax);
            }
            return true;
          },
        },
      };

    case 'number':
      const parsedMin = parseNumericValue(min);
      const parsedMax = parseNumericValue(max);
      return {
        validate: {
          numberRange: value => {
            if (!value) return true;
            const numValue = parseNumericValue(value);
            if (numValue === null) return true;

            if (parsedMin !== null && parsedMax !== null) {
              if (numValue < parsedMin || numValue > parsedMax) {
                return `Value must be between ${parsedMin} and ${parsedMax}`;
              }
            } else if (parsedMin !== null && numValue < parsedMin) {
              return `Value must be greater than ${parsedMin}`;
            } else if (parsedMax !== null && numValue > parsedMax) {
              return `Value must be less than ${parsedMax}`;
            }
            return true;
          },
        },
      };

    case 'text':
    case 'textarea':
      const parsedMinLength = parseNumericValue(min);
      const parsedMaxLength = parseNumericValue(max);
      return {
        validate: {
          textLength: value => {
            if (!value) return true;
            if (parsedMinLength !== null && value.length < parsedMinLength) {
              return `Must be at least ${parsedMinLength} characters`;
            }
            if (parsedMaxLength !== null && value.length > parsedMaxLength) {
              return `Must be no more than ${parsedMaxLength} characters`;
            }
            return true;
          },
        },
      };

    default:
      return {};
  }
};

const applyCustomValidator = (
  validatorName,
  validationParams = {},
  getValues = () => {},
  fieldName = null,
) => {
  // Check pattern validators first
  const patternValidator = patternValidators[validatorName];
  if (patternValidator) {
    return patternValidator;
  }

  // Check function validators
  const functionValidator = functionValidators[validatorName];

  if (functionValidator) {
    if (typeof functionValidator.validate === 'function') {
      return {
        validate: value => {
          return functionValidator.validate(value, validationParams, getValues(), fieldName);
        },
      };
    } else if (typeof functionValidator.validate === 'object') {
      return {
        validate: functionValidator.validate,
      };
    }
  }

  return {};
};

const addValidators = (
  type = 'text',
  min = null,
  max = null,
  required = false,
  validatorName = null,
  validationParams = {},
  getValues = () => {},
  fieldName = null,
) => {
  let validators = {};

  if (required) {
    validators.required = {
      value: true,
      message: 'This field is required',
    };
  }

  if (min !== null || max !== null) {
    const minMaxValidators = applyMinMaxValidators(type, min, max);
    if (minMaxValidators.validate) {
      validators.validate = {
        ...validators.validate,
        ...minMaxValidators.validate,
      };
    }
  }

  if (validatorName) {
    const customValidator = applyCustomValidator(
      validatorName,
      validationParams,
      getValues,
      fieldName,
    );
    if (customValidator.pattern) {
      validators.pattern = customValidator.pattern;
    }
    if (customValidator.validate) {
      validators.validate = {
        ...validators.validate,
        [validatorName]: customValidator.validate,
      };
    }
  }

  return validators;
};

export default addValidators;
