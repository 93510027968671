import cn from 'classnames';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import styles from './InputGroup.module.scss';

export const InputGroup = ({
  title,
  description,
  tooltip,
  error,
  children,
  className,
  inline = false,
  strong = false,
}) => (
  <div className={cn(styles.root, { [className]: className, [styles.rootInline]: inline })}>
    <span
      className={cn(styles.title, {
        [styles.titleStrong]: strong,
        [styles.titleError]: error,
        [styles.titleNoMargin]: !!description,
      })}>
      {title}
      {tooltip && (
        <OverlayTrigger placement="bottom" overlay={<Tooltip>{tooltip}</Tooltip>}>
          <span className={styles.tooltip}>
            <FeatherIcon icon="info" />
          </span>
        </OverlayTrigger>
      )}
    </span>
    {description && (
      <span className="u-margin-bottom--small d-block t-tiny text-secondary">{description}</span>
    )}
    <div className={styles.input}>{children}</div>
  </div>
);

InputGroup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  tooltip: PropTypes.string,
  error: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  inline: PropTypes.bool,
  strong: PropTypes.bool,
};
