import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import cn from 'classnames';
import adminDivisions from 'constants/country-admin-divisions';
import { MESSAGE_STATE_ERROR } from 'constants/message-app-state-contants';
import evaluateFieldConditions from 'helpers/evaluate-field-conditions';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { CandidateQuestionnaireSelectorDetails } from './components/CandidateQuestionnaireSelectorDetails/CandidateQuestionnaireSelectorDetails';
import { CustomReferenceCheckDetails } from './components/CustomReferenceCheckDetails/CustomReferenceCheckDetails';
import { DocumentSigningSelectorDetails } from './components/DocumentSigningSelectorDetails/DocumentSigningSelectorDetails';
import { ReferenceSelectorDetails } from './components/ReferenceSelectorDetails/ReferenceSelectorDetails';
import { DynamicTypeInput } from 'components/Form/DynamicTypeInput/DynamicTypeInput';
import { Button, InputGroup, Select, ToggleSwitchInput } from 'components/FormComponents';

import { messagePopUp } from 'api/app-slice';

import styles from './ChecksSelector.module.scss';

export const SelectorTypes = {
  REFERENCE: 'reference',
  POLICE_VETTING: 'police_vetting',
  BACKGROUND_CHECKS: 'background_checks',
  QUESTIONNAIRE: 'candidate_questionnaire',
  CUSTOM_REFERENCE: 'custom_reference',
};

export const ChecksSelector = forwardRef(
  (
    {
      companyChecks = [],
      form,
      forms,
      targetForm,
      brands,
      bundles,
      type,
      bulk,
      className,
      useDocuments = false,
      allowUploads = false,
      getBrandUploads = () => {},
      setValidity = () => {},
      onChange = () => {},
      onSubmit = () => {},
      getUpload = () => {},
      signUpload = () => {},
      createUpload = () => {},
      deleteUpload = () => {},
    },
    ref,
  ) => {
    const {
      register,
      watch,
      reset,
      control,
      setValue,
      formState: { errors },
      handleSubmit,
      unregister,
    } = useForm();

    const dispatch = useDispatch();

    const { fields: checks, append, remove } = useFieldArray({ control, name: 'checks' });

    const values = useWatch({ control });

    const bundleId = useWatch({ control, name: 'candidate.bundle_id' });
    const brandId = useWatch({ control, name: 'candidate.brand_id' });
    const documentId = useWatch({ control, name: 'candidate.document_id' });

    const [enabledChecks, setEnabledChecks] = useState({});
    const [documentsEnabled, setDocumentsEnabled] = useState(false);
    const [documents, setDocuments] = useState([]);

    // Set bundle values
    useEffect(() => {
      if (!bundleId) return;
      const bundle = bundles.find(bundle => bundle.id === bundleId);
      setValue('checks', bundle.checks_data);

      const brand = brands.find(brand => brand.id === bundle.brand_id);
      if (!brand) {
        dispatch(
          messagePopUp({
            text: 'Bundle brand is invalid. We will use the default brand instead.',
            state: MESSAGE_STATE_ERROR,
            hide: true,
          }),
        );
      } else {
        setValue('candidate.brand_id', brand.id);
      }
    }, [bundleId, brands?.length]);

    // Get brand documents
    useEffect(() => {
      if (!brandId || !useDocuments) {
        setDocuments([]);
        return;
      }

      const brand = brands.find(brand => brand.id === brandId);
      if (!brand?.documents_active) {
        setDocuments([]);
        return;
      }

      getBrandUploads(brandId).then(({ payload }) => {
        setDocuments(payload.uploads);
      });
    }, [brandId, useDocuments, brands?.length]);

    // Expose functions
    useImperativeHandle(ref, () => ({
      submit: () => {
        handleSubmit(data => {
          delete data.checks[-1];
          onSubmit(data);
        })();
      },
      reset: values => {
        reset(values);
      },
    }));

    // Clear Bundle
    const clearBundle = () => {
      reset({ checks: [] });
    };

    // Set enabled checks
    useEffect(() => {
      let enabledChecks = {};
      checks.forEach((check, index) => {
        enabledChecks[check.type] = index;
      });
      setEnabledChecks(enabledChecks);
    }, [checks]);

    // Set validity
    useEffect(() => {
      setValidity(checks.length > 0);
    }, [checks?.length]);

    // OnChange
    useEffect(() => {
      onChange(values);
    }, [values]);

    const renderDetails = companyCheck => {
      if (!companyCheck.type) return null;

      switch (companyCheck.type) {
        case 'reference':
          return (
            <ReferenceSelectorDetails
              key={companyCheck.type}
              index={enabledChecks.reference}
              append={append}
              remove={remove}
              register={register}
              watch={watch}
              credits={companyCheck?.credits}
              setValue={setValue}
              form={form}
              forms={forms}
              type={type}
              bulk={bulk}
              targetForm={targetForm}
              documents={documents}
              signUpload={signUpload}
              createUpload={createUpload}
              handleDeleteUpload={deleteUpload}
              allowUploads={allowUploads}
            />
          );
        case 'custom_reference':
          return (
            <CustomReferenceCheckDetails
              key={companyCheck.type}
              index={enabledChecks.custom_reference}
              append={append}
              remove={remove}
              register={register}
              control={control}
              watch={watch}
              setValue={setValue}
              credits={companyCheck?.credits}
              bulk={bulk}
              documents={documents}
              forms={forms}
              signUpload={signUpload}
              createUpload={createUpload}
              handleDeleteUpload={deleteUpload}
              allowUploads={allowUploads}
            />
          );
        case 'candidate_questionnaire':
          return (
            <CandidateQuestionnaireSelectorDetails
              key={companyCheck.type}
              index={enabledChecks.candidate_questionnaire}
              append={append}
              remove={remove}
              register={register}
              watch={watch}
              credits={companyCheck?.credits}
              candidateForms={(forms.questionnaires || []).filter(form => !form.archived)}
            />
          );
        case 'document_signing':
          return (
            <DocumentSigningSelectorDetails
              key={companyCheck.type}
              index={enabledChecks.document_signing}
              documents={documents}
              watch={watch}
              register={register}
              append={append}
              remove={remove}
              setValue={setValue}
              control={control}
              getUpload={getUpload}
              signUpload={signUpload}
              createUpload={createUpload}
              deleteUpload={deleteUpload}
              credits={companyCheck?.credits}
            />
          );
        default:
          return (
            <SelectorDetails
              key={companyCheck.type}
              companyCheck={companyCheck}
              index={enabledChecks[companyCheck.type]}
              control={control}
              errors={errors}
              append={append}
              remove={remove}
              watch={watch}
              register={register}
              unregister={unregister}
              setValue={setValue}
            />
          );
      }
    };

    return (
      <form className={cn(styles.root, { [className]: className })}>
        {bundles?.length > 0 && (
          <div className="card card-with-border u-padding u-margin-bottom">
            <InputGroup title="Bundle" className="u-margin-bottom--0">
              <Select
                name="candidate.bundle_id"
                register={register}
                value={bundleId}
                placeholder="Select Bundle..."
                useSearch>
                {bundles.map(bundle => (
                  <Select.Item key={bundle.id} value={bundle.id}>
                    {bundle.name}
                  </Select.Item>
                ))}
              </Select>
              {bundleId && (
                <a className={styles.clear_bundle} onClick={clearBundle}>
                  Clear Bundle
                </a>
              )}
            </InputGroup>
          </div>
        )}

        {brands?.length > 0 && (
          <div className="card card-with-border u-padding u-margin-bottom">
            <InputGroup title="Brand" className="u-margin-bottom--0">
              <Select
                name="candidate.brand_id"
                register={register}
                value={brandId}
                placeholder="Select Brand..."
                useDefault>
                {brands.map(brand => (
                  <Select.Item key={brand.id} value={brand.id}>
                    {brand.name}
                  </Select.Item>
                ))}
              </Select>
            </InputGroup>
          </div>
        )}

        {documents.length > 0 && (
          <ToggleSwitchInput
            text="Attach documents"
            subtext="Select a document you wish to send to the candidate"
            onChange={e => setDocumentsEnabled(e.target.checked)}
            value={JSON.parse(documentsEnabled)}>
            {documentsEnabled && (
              <InputGroup title="Documents">
                <Select
                  name="candidate.document_id"
                  placeholder="Document"
                  register={register}
                  value={documentId}>
                  {documents.map(document => (
                    <Select.Item key={document.id} value={document.id}>
                      {document.file_name}
                    </Select.Item>
                  ))}
                </Select>
              </InputGroup>
            )}
          </ToggleSwitchInput>
        )}

        <div className="u-divider" />

        {(!type || type === SelectorTypes.BACKGROUND_CHECKS) &&
          (companyChecks || []).map(companyCheck => renderDetails(companyCheck))}
        {type === SelectorTypes.REFERENCE && (
          <ReferenceSelectorDetails
            index={enabledChecks.reference}
            append={append}
            remove={remove}
            register={register}
            watch={watch}
            credits={companyChecks.find(companyCheck => companyCheck.type === 'reference')?.credits}
            setValue={setValue}
            form={form}
            forms={forms}
            type={type}
            bulk={bulk}
            targetForm={targetForm}
            documents={documents}
            signUpload={signUpload}
            createUpload={createUpload}
            handleDeleteUpload={deleteUpload}
            allowUploads={allowUploads}
          />
        )}
        {type === SelectorTypes.POLICE_VETTING && (
          <SelectorDetails
            companyCheck={companyChecks.find(
              companyCheck => companyCheck.type === 'police_vetting',
            )}
            index={enabledChecks?.police_vetting}
            control={control}
            append={append}
            remove={remove}
            watch={watch}
            register={register}
            unregister={unregister}
            setValue={setValue}
          />
        )}
        {type === SelectorTypes.QUESTIONNAIRE && (
          <CandidateQuestionnaireSelectorDetails
            index={enabledChecks.candidate_questionnaire}
            append={append}
            remove={remove}
            register={register}
            watch={watch}
            credits={
              companyChecks.find(companyCheck => companyCheck.type === 'candidate_questionnaire')
                ?.credits
            }
            candidateForms={(forms.questionnaires || []).filter(form => !form.archived)}
          />
        )}
        {type === SelectorTypes.CUSTOM_REFERENCE && (
          <CustomReferenceCheckDetails
            index={enabledChecks.custom_reference}
            append={append}
            remove={remove}
            control={control}
            register={register}
            watch={watch}
            setValue={setValue}
            documents={documents}
            credits={
              companyChecks.find(companyCheck => companyCheck.type === 'custom_reference')?.credits
            }
            forms={forms}
            signUpload={signUpload}
            createUpload={createUpload}
            handleDeleteUpload={deleteUpload}
            allowUploads={allowUploads}
          />
        )}
      </form>
    );
  },
);

const SelectorDetails = ({
  companyCheck,
  index,
  errors,
  control,
  append = () => {},
  remove = () => {},
  watch = () => {},
  register = () => {},
  unregister = () => {},
  setValue = () => {},
}) => {
  const checkType = companyCheck.check_type;
  const [enabled, setEnabled] = useState(false);
  const [dynamicFields, setDynamicFields] = useState({});

  // Initialize dynamic arrays
  useEffect(() => {
    if (!enabled || index < 0) return;

    const dynamicArrays =
      checkType.client_fields?.filter(field => field.type === 'dynamic_array') || [];

    dynamicArrays.forEach(field => {
      const fieldValues = watch(`checks.${index}.details.${field.code}`) || [];
      const positions = [];

      if (fieldValues.length > 0) {
        for (let i = 0; i < fieldValues.length; i++) {
          positions.push(i);
        }
      } else if (field.start_initialized) {
        positions.push(0);
        setValue(`checks.${index}.details.${field.code}`, [{}], {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      }

      setDynamicFields(curr => ({
        ...curr,
        [field.code]: positions,
      }));
    });
  }, [enabled, index]);

  // Add record to dynamic array
  const addToDynamicArray = field => {
    const arrayPositions = dynamicFields[field.code];
    const newIndex = arrayPositions.length;

    // Update positions
    setDynamicFields(curr => ({
      ...curr,
      [field.code]: [...curr[field.code], newIndex],
    }));

    // Update form values
    const currentValues = watch(`checks.${index}.details.${field.code}`) || [];
    setValue(`checks.${index}.details.${field.code}`, [...currentValues, {}], {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  // Remove record from dynamic array
  const removeFromDynamicArray = field => {
    const newValue = [...dynamicFields[field.code]];
    const removedIndex = newValue.pop();

    // Update positions
    setDynamicFields(curr => ({ ...curr, [field.code]: newValue }));

    // Properly unregister the field
    unregister(`checks.${index}.details.${field.code}.${removedIndex}`);

    // Update form values
    const currentValues = watch(`checks.${index}.details.${field.code}`) || [];
    setValue(`checks.${index}.details.${field.code}`, currentValues.slice(0, -1), {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  useEffect(() => {
    if (enabled && isNaN(index)) {
      append({
        type: checkType.slug,
        details: {},
      });
    } else if (!enabled && index >= 0) {
      remove(index);
    }
  }, [enabled]);

  useEffect(() => {
    if (index >= 0 && !enabled) setEnabled(true);
    else if (isNaN(index) && enabled) setEnabled(false);
  }, [index]);

  const metConditions = field => {
    if (!field.conditions) return true;

    // Get the current form values for this check's details
    const formValues = watch(`checks.${index}.details`) || {};
    const conditionsMet = evaluateFieldConditions(field.conditions, formValues);

    // Clear value if conditions are not met
    if (!conditionsMet) {
      const fieldPath = `checks.${index}.details.${field.code}`;
      const currentValue = watch(fieldPath);
      if (currentValue) {
        setValue(fieldPath, null);
      }
    }

    return conditionsMet;
  };

  const getFieldOptions = field => {
    // If options_source is specified, try to get from adminDivisions
    if (field.options_source && adminDivisions[field.options_source]) {
      const options = adminDivisions[field.options_source];
      // Verify each option has name and value properties
      return options.map(option => ({
        name: option.name || option.value,
        value: option.value,
      }));
    }

    // If regular options array exists, ensure consistent format
    if (Array.isArray(field.options)) {
      return field.options.map(option => {
        if (typeof option === 'object' && option.name && option.value) {
          return option;
        }
        return {
          name: option.name || option.value || option,
          value: option.value || option,
        };
      });
    }

    // If it's a reference to company check configuration
    if (typeof field.options === 'string') {
      const configOptions = getConfigurationValue(field.options);
      return Array.isArray(configOptions)
        ? configOptions.map(option => ({
            name: option.name || option.value || option,
            value: option.value || option,
          }))
        : [];
    }

    return [];
  };

  const getConfigurationValue = keysString => {
    const companyCheckKeys = keysString.split('.');
    let value = companyCheck;

    companyCheckKeys.forEach(key => {
      value = value[key];
    });

    return value;
  };

  return (
    <ToggleSwitchInput
      text={checkType.title}
      logo={checkType.logo}
      subtext={(companyCheck.credits < 0 ? 'Unlimited' : companyCheck.credits) + ' remaining'}
      value={enabled}
      onChange={e => setEnabled(e.target.checked)}>
      {checkType.client_fields?.length > 0 &&
        enabled &&
        index >= 0 &&
        checkType.client_fields?.map(field => {
          if (!metConditions(field)) return;

          if (field.type === 'dynamic_array') {
            const arrayPositions = dynamicFields[field.code] || [];
            return (
              <>
                {arrayPositions?.map(arrayIndex => (
                  <div
                    key={`${field.code}-${arrayIndex}`}
                    {...(field.sub_fields.length > 1
                      ? {
                          title: `${arrayIndex + 1}. ${field.array_item_titles || field.title}`,
                          className: 'card card-with-border u-padding u-margin-bottom',
                        }
                      : {})}>
                    {field.sub_fields?.map(subField => {
                      const fieldName = `checks.${index}.details.${field.code}.${arrayIndex}.${subField.code}`;
                      const error = fieldName
                        .split('.')
                        .reduce((prev, curr) => (prev ? prev[curr] : null), errors);
                      return (
                        <DynamicTypeInput
                          key={subField.code}
                          fieldName={fieldName}
                          type={subField.type}
                          title={subField.title}
                          description={subField.description}
                          tooltip={subField.tooltip}
                          autoComplete={subField.auto_complete}
                          placeholder={subField.placeholder || subField.title}
                          defaultValue={subField.default_value}
                          min={subField.min}
                          max={subField.max}
                          required={!!subField.required}
                          validatorName={subField.validator_name}
                          validationParams={subField.validation_params}
                          fieldOptions={getFieldOptions(subField)}
                          useDefault={subField.use_default}
                          useSearch={subField.use_search}
                          register={register}
                          watch={watch}
                          control={control}
                          error={error?.type}
                          noErrorMessage={true}
                        />
                      );
                    })}
                  </div>
                ))}
                <div className="u-flex-box u-flex-justify-between">
                  <Button small onClick={() => addToDynamicArray(field)}>
                    Add {field.array_item_titles || field.title}
                  </Button>
                  <Button
                    type="delete"
                    small
                    disabled={arrayPositions?.length < 1}
                    onClick={() => removeFromDynamicArray(field)}>
                    Remove Last
                  </Button>
                </div>
              </>
            );
          }

          const fieldName = `checks.${index}.details.${field.code}`;
          const required = checkType.client_required_fields?.includes(field.code);
          const fieldTitle = required ? `${field.title} *` : field.title;
          const error = fieldName.split('.').reduce(function (prev, curr) {
            return prev ? prev[curr] : null;
          }, errors);

          return (
            <DynamicTypeInput
              key={field.code}
              fieldName={fieldName}
              type={field.type}
              title={fieldTitle}
              description={field.description}
              tooltip={field.tooltip}
              fieldOptions={getFieldOptions(field)}
              placeholder={field.placeholder}
              defaultValue={field.default_value}
              min={field.min}
              max={field.max}
              useDefault={field.use_default}
              useSearch={field.use_search}
              arrayStartInitialized={field.array_start_initialized}
              validatorName={field.validator_name}
              validationParams={field.validation_params}
              required={required}
              control={control}
              error={error?.message || error?.type}
              noErrorMessage={false}
              register={register}
              watch={watch}
            />
          );
        })}
    </ToggleSwitchInput>
  );
};
