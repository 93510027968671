import { useEffect, useState } from 'react';

import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import DatePickerComponent from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from './DatePicker.module.scss';

export const DatePicker = ({
  value,
  useDefaultToday = true,
  minDate,
  maxDate,
  format = 'dd-MM-yyyy',
  outputFormat = 'DD/MM/YYYY',
  className,
  onChange = () => {},
  error,
  noErrorMessage = false,
}) => {
  const [date, setDate] = useState(() => {
    // First check for controlled value
    if (value) {
      const dateValue = moment(value, outputFormat);
      return dateValue.isValid() ? dateValue.toDate() : null;
    }
    // Otherwise use default today if enabled
    return useDefaultToday ? new Date() : null;
  });

  // Only update if this is a controlled component
  useEffect(() => {
    if (value === undefined) return; // Skip if uncontrolled

    const dateValue = moment(value, outputFormat);
    setDate(dateValue.isValid() ? dateValue.toDate() : null);
  }, [value]);

  // Send date value in output format
  useEffect(() => {
    if (!date) return;

    const newValue = moment(date).format(outputFormat);
    onChange(newValue);
  }, [date]);

  return (
    <div className={cn(styles.root, { [styles.rootInvalid]: !!error, [className]: className })}>
      <DatePickerComponent
        selected={date}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={format}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        onChange={setDate}
      />
      {error && !noErrorMessage && <span className={styles.error}>{error}</span>}
    </div>
  );
};

DatePicker.propTypes = {
  value: PropTypes.string,
  useDefaultToday: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  format: PropTypes.string,
  outputFormat: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  noErrorMessage: PropTypes.bool,
};
