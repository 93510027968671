import { useEffect, useState } from 'react';

import cn from 'classnames';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';

import styles from './Input.module.scss';

export const Input = ({
  name,
  type = 'text',
  autoComplete,
  placeholder,
  defaultValue,
  min,
  max,
  minLength,
  maxLength,
  validators = {},
  icon = '',
  actionIcon,
  register = () => {},
  error = '',
  noErrorMessage,
  disabled,
  className,
  onActionClick = () => {},
  inputProps = {},
}) => {
  const [passwordRevealed, setPasswordRevealed] = useState(false);

  return (
    <div
      className={cn(styles.root, {
        [className]: className,
        [styles.rootWithError]: error && !noErrorMessage,
      })}>
      {icon && <FeatherIcon className={styles.icon} icon={icon} />}

      <input
        name={name}
        type={passwordRevealed ? 'text' : type}
        autoComplete={autoComplete}
        placeholder={placeholder}
        defaultValue={defaultValue}
        min={min}
        max={max}
        minLength={minLength}
        maxLength={maxLength}
        {...register(name, validators)}
        disabled={disabled}
        className={cn(styles.input, {
          [styles.invalid]: !!error,
          [styles.inputWithIcon]: !!icon,
          [styles.disabled]: disabled,
        })}
        {...inputProps}
      />

      {type === 'password' && (
        <span
          className={cn(styles.showPassword, 'u-link', 'dark')}
          onMouseDown={() => setPasswordRevealed(true)}
          onMouseUp={() => setPasswordRevealed(false)}
          onMouseLeave={() => setPasswordRevealed(false)}>
          Show
        </span>
      )}

      {actionIcon && (
        <FeatherIcon icon={actionIcon} className={styles.actionButton} onClick={onActionClick} />
      )}
      {error && !noErrorMessage && <span className={styles.error}>{error}</span>}
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'date', 'password', 'email', 'tel', 'url']),
  autoComplete: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.number,
  max: PropTypes.number,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  validators: PropTypes.object,
  icon: PropTypes.string,
  actionIcon: PropTypes.string,
  register: PropTypes.func,
  error: PropTypes.string,
  noErrorMessage: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onActionClick: PropTypes.func,
  inputProps: PropTypes.object,
};
