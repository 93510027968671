export const ADDRESS_STRUCTURE = {
  // OCEANIA
  Australia: {
    street_address: 'Street Address',
    suburb: 'Suburb',
    city_town: 'City/Town',
    region_state: 'State/Territory',
    postal_code: 'Postcode',
  },
  'New Zealand': {
    street_address: 'Street Address',
    suburb: 'Suburb',
    city_town: 'City/Town',
    region_state: 'Region',
    postal_code: 'Postcode',
  },
  Fiji: {
    street_address: 'Street Address',
    suburb: 'Town/Village',
    city_town: 'City/Town',
    region_state: 'Province',
  },
  'Papua New Guinea': {
    street_address: 'Street Address',
    suburb: 'Suburb/Town/Village',
    city_town: 'City/Town',
    region_state: 'Province',
    postal_code: 'Postal Code',
  },
  'Solomon Islands': {
    street_address: 'Street Address',
    suburb: 'Town/Village',
    city_town: 'City/Town',
    region_state: 'Province',
  },
  Vanuatu: {
    street_address: 'Street Address',
    suburb: 'Town/Village',
    city_town: 'City/Town',
    region_state: 'Province',
  },
  Samoa: {
    street_address: 'Street Address',
    suburb: 'Village',
    city_town: 'Village',
    region_state: 'District',
  },
  Tonga: {
    street_address: 'Street Address',
    suburb: 'Village',
    city_town: 'Village',
    region_state: 'Island Group',
  },
  'Cook Islands': {
    street_address: 'Street Address',
    suburb: 'Village',
    city_town: 'Village',
    region_state: 'Island',
  },
  'French Polynesia': {
    street_address: 'Street Address',
    suburb: 'City/Commune',
    city_town: 'City/Commune',
    region_state: 'Island',
    postal_code: 'Code Postal',
  },
  'New Caledonia': {
    street_address: 'Street Address',
    suburb: 'City/Commune',
    city_town: 'City/Commune',
    region_state: 'Province',
    postal_code: 'Code Postal',
  },

  // NORTH AMERICA
  'United States of America': {
    street_address: 'Street Address',
    suburb: 'Neighborhood',
    city_town: 'City',
    region_state: 'State',
    postal_code: 'ZIP Code',
  },
  Canada: {
    street_address: 'Street Address',
    suburb: 'Neighborhood/Suburb',
    city_town: 'City',
    region_state: 'Province',
    postal_code: 'Postal Code',
  },
  Mexico: {
    street_address: 'Street Address',
    suburb: 'Colonia (Neighborhood)',
    city_town: 'City',
    region_state: 'State',
    postal_code: 'Postal Code',
  },

  // SOUTH AMERICA
  Brazil: {
    street_address: 'Street Address',
    suburb: 'Neighborhood (Bairro)',
    city_town: 'City',
    region_state: 'State',
    postal_code: 'CEP',
  },
  Argentina: {
    street_address: 'Street Address',
    suburb: 'Neighborhood/Suburb',
    city_town: 'City',
    region_state: 'Province',
    postal_code: 'Postal Code',
  },
  Colombia: {
    street_address: 'Street Address',
    suburb: 'Neighborhood (Barrio)',
    city_town: 'City',
    region_state: 'Department',
    postal_code: 'Postal Code',
  },

  // EUROPE
  'United Kingdom': {
    street_address: 'Street Address',
    suburb: 'Suburb',
    city_town: 'City',
    region_state: 'County',
    postal_code: 'Postcode',
  },
  France: {
    street_address: 'Street Address',
    suburb: 'Neighborhood/Arrondissement',
    city_town: 'City',
    region_state: 'Department',
    postal_code: 'Postal Code',
  },
  Germany: {
    street_address: 'Street Address',
    suburb: 'Neighborhood/Bezirk',
    city_town: 'City',
    region_state: 'State',
    postal_code: 'PLZ',
  },
  Italy: {
    street_address: 'Street Address',
    suburb: 'Neighborhood/Quartiere',
    city_town: 'City',
    region_state: 'Province',
    postal_code: 'CAP',
  },
  Spain: {
    street_address: 'Street Address',
    suburb: 'Neighborhood/Barrio',
    city_town: 'City',
    region_state: 'Province',
    postal_code: 'Postal Code',
  },

  // ASIA
  Singapore: {
    street_address: 'Block and Street Name',
    suburb: 'Neighborhood',
    city_town: 'City',
    region_state: 'State',
    postal_code: 'Postal Code',
  },
  'China, Peoples Republic of': {
    street_address: 'Street Address',
    suburb: 'Neighborhood/District',
    city_town: 'City',
    region_state: 'Province/Municipality',
    postal_code: 'Postal Code',
  },
  Japan: {
    street_address: 'Street Address',
    suburb: 'Neighborhood (Chome)',
    sublocality_level_2: 'Block/Chome',
    city_town: 'City/Shi',
    region_state: 'Prefecture',
    postal_code: 'Postal Code',
  },
  'Korea, Republic of': {
    street_address: 'Street Address',
    suburb: 'Neighborhood/Dong',
    city_town: 'City/Si',
    region_state: 'Province/Do',
    postal_code: 'Postal Code',
  },
  India: {
    street_address: 'Street Address',
    suburb: 'Neighborhood/Locality',
    city_town: 'City',
    region_state: 'State/Union Territory',
    postal_code: 'PIN Code',
  },
  Indonesia: {
    street_address: 'Street Address',
    suburb: 'Neighborhood/Kelurahan',
    city_town: 'City/Kota',
    region_state: 'Province',
    postal_code: 'Postal Code',
  },
  Malaysia: {
    street_address: 'Street Address',
    suburb: 'Neighborhood/Taman',
    city_town: 'City',
    region_state: 'State/Negeri',
    postal_code: 'Postal Code',
  },
  Thailand: {
    street_address: 'Street Address',
    suburb: 'Neighborhood/Tambon',
    city_town: 'City',
    region_state: 'Province/Changwat',
    postal_code: 'Postal Code',
  },
  Vietnam: {
    street_address: 'Street Address',
    suburb: 'Ward/Phuong',
    city_town: 'City',
    region_state: 'Province/Tinh',
    postal_code: 'Postal Code',
  },
  Philippines: {
    street_address: 'Street Address',
    suburb: 'Barangay',
    city_town: 'City/Municipality',
    region_state: 'Province',
    postal_code: 'ZIP Code',
  },
  Taiwan: {
    street_address: 'Street Address',
    suburb: 'Neighborhood/Village',
    city_town: 'City',
    region_state: 'County/City',
    postal_code: 'Postal Code',
  },
  'Hong Kong': {
    street_address: 'Street Address',
    suburb: 'District',
    city_town: 'District',
    region_state: 'Region',
  },

  // MIDDLE EAST
  'United Arab Emirates': {
    street_address: 'Street Address',
    suburb: 'Neighborhood',
    city_town: 'City',
    region_state: 'Emirate',
    postal_code: 'Postal Code',
  },
  'Saudi Arabia': {
    street_address: 'Street Address',
    suburb: 'District/Neighborhood',
    city_town: 'City',
    region_state: 'Province',
    postal_code: 'Postal Code',
  },
  Bangladesh: {
    street_address: 'Street Address',
    suburb: 'Area/Mohalla',
    city_town: 'City',
    region_state: 'District',
    postal_code: 'Postal Code',
  },
  Pakistan: {
    street_address: 'Street Address',
    suburb: 'Neighborhood/Mohalla',
    city_town: 'City',
    region_state: 'Province/Territory',
    postal_code: 'Postal Code',
  },

  // Default structure for countries not specifically mapped
  default: {
    street_address: 'Street Address',
    suburb: 'Neighborhood/Suburb',
    city_town: 'City/Town',
    region_state: 'Region/State/Province',
    postal_code: 'Postal Code',
  },
};

// Helper function to get address structure for a country
export const getAddressStructure = country => {
  return ADDRESS_STRUCTURE[country] || ADDRESS_STRUCTURE['default'];
};

export const POSTAL_CODE_FORMATS = {
  Singapore: '^\\d{6}$',
  Japan: '^\d{3}-\d{4}$',
  'Korea, Republic of': '^\\d{5}$',
  'China, Peoples Republic of': '^\\d{6}$',
  India: '^\\d{6}$',
  Australia: '^\\d{4}$',
  'New Zealand': '^\\d{4}$',
  Malaysia: '^\\d{5}$',
  Thailand: '^\\d{5}$',
  Vietnam: '^\\d{6}$',
  Philippines: '^\\d{4}$',
  Indonesia: '^\\d{5}$',
  Taiwan: '^\d{5}$',
  Bangladesh: '^\\d{4}$',
  Pakistan: '^\\d{5}$',
  'French Polynesia': '^987\\d{2}$',
  'New Caledonia': '^988\\d{2}$',
  'Papua New Guinea': '^\\d{3}$',
  'Hong Kong': null,
  default: '^[0-9A-Za-z\\s-]{3,10}$',
};

export const VALIDATION_MESSAGES = {
  postal_code: {
    Singapore: 'Please enter a valid 6-digit postal code',
    Japan: 'Please enter a valid postal code in the format XXX-XXXX',
    default: 'Please enter a valid postal code',
  },
};

export const ADDRESS_FIELDS = [
  {
    fieldName: 'street_address',
    autocomplete: 'street_address',
    maxLength: 255,
    googleComponents: ['street_number', 'route', 'premise', 'subpremise'],
  },
  {
    fieldName: 'suburb',
    autocomplete: 'suburb',
    maxLength: 255,
    googleComponents: ['sublocality_level_1', 'neighborhood'],
  },
  {
    fieldName: 'city_town',
    autocomplete: 'city_town',
    maxLength: 100,
    googleComponents: ['locality', 'postal_town'],
  },
  {
    fieldName: 'sublocality_level_2',
    autocomplete: 'sublocality-level-2',
    maxLength: 100,
    googleComponents: ['sublocality_level_2', 'sublocality'],
  },
  {
    fieldName: 'sublocality_level_3',
    autocomplete: 'sublocality-level-3',
    maxLength: 100,
    googleComponents: ['sublocality_level_3'],
  },
  {
    fieldName: 'sublocality_level_4',
    autocomplete: 'sublocality-level-4',
    maxLength: 100,
    googleComponents: ['sublocality_level_4'],
  },
  {
    fieldName: 'sublocality_level_5',
    autocomplete: 'sublocality-level-5',
    maxLength: 100,
    googleComponents: ['sublocality_level_5'],
  },
  {
    fieldName: 'region_state',
    autocomplete: 'administrative_area_level_1',
    maxLength: 100,
    googleComponents: ['administrative_area_level_1'],
  },
  {
    fieldName: 'administrative_area_level_2',
    autocomplete: 'administrative-area-level-2',
    maxLength: 100,
    googleComponents: ['administrative_area_level_2'],
  },
  {
    fieldName: 'administrative_area_level_3',
    autocomplete: 'administrative-area-level-3',
    maxLength: 100,
    googleComponents: ['administrative_area_level_3'],
  },
  {
    fieldName: 'administrative_area_level_4',
    autocomplete: 'administrative-area-level-4',
    maxLength: 100,
    googleComponents: ['administrative_area_level_4'],
  },
  {
    fieldName: 'administrative_area_level_5',
    autocomplete: 'administrative-area-level-5',
    maxLength: 100,
    googleComponents: ['administrative_area_level_5'],
  },
  {
    fieldName: 'administrative_area_level_6',
    autocomplete: 'administrative-area-level-6',
    maxLength: 100,
    googleComponents: ['administrative_area_level_6'],
  },
  {
    fieldName: 'administrative_area_level_7',
    autocomplete: 'administrative-area-level-7',
    maxLength: 100,
    googleComponents: ['administrative_area_level_7'],
  },
  {
    fieldName: 'postal_code',
    autocomplete: 'postal-code',
    maxLength: 20,
    googleComponents: ['postal_code'],
  },
];
