// country-versions-helper.js
import moment from 'moment';

export const getCountriesToCheck = (formValues, config = {}) => {
  if (!formValues || !config?.mode) return [];
  if (config.mode !== 'address_history') return [];

  const countryDurations = new Map();
  const now = moment();
  const checkPeriodYears = config.required_address_years || 10;
  const cutoffDate = moment().subtract(checkPeriodYears, 'years');

  // Convert addresses into sorted date ranges with country info
  let dateRanges = [];

  // Add current address
  if (formValues.residential_address?.country && formValues.current_address_residence_from) {
    const fromDate = moment(formValues.current_address_residence_from);
    if (fromDate.isValid()) {
      dateRanges.push({
        country: formValues.residential_address.country,
        from: fromDate,
        to: now,
      });
    }
  }

  // Add previous addresses
  if (Array.isArray(formValues.previous_addresses)) {
    dateRanges = dateRanges.concat(
      formValues.previous_addresses
        .filter(
          address =>
            address?.previous_address?.country &&
            address?.previous_address_residence_from &&
            address?.previous_address_residence_to,
        )
        .map(address => ({
          country: address.previous_address.country,
          from: moment(address.previous_address_residence_from),
          to: moment(address.previous_address_residence_to),
        }))
        .filter(range => range.from.isValid() && range.to.isValid()),
    );
  }

  // Sort ranges by 'to' date, descending
  dateRanges.sort((a, b) => b.to.valueOf() - a.to.valueOf());

  // Process each range
  dateRanges.forEach(range => {
    // Check if any part of the range falls within check period
    if (range.from.isBefore(now) && range.to.isAfter(cutoffDate)) {
      const durationMonths = range.to.diff(range.from, 'months');

      if (durationMonths > 0) {
        countryDurations.set(
          range.country,
          (countryDurations.get(range.country) || 0) + durationMonths,
        );
      }
    }
  });

  // Filter countries by minimum residence duration
  const minMonths = config.minimum_residence_months || 6;
  const qualifyingCountries = Array.from(countryDurations.entries())
    .filter(([_, duration]) => duration >= minMonths)
    .map(([country]) => country);

  // Add must_include countries
  const mustIncludeCountries = Array.isArray(config.must_include_countries)
    ? config.must_include_countries.filter(Boolean)
    : [];

  // Combine and deduplicate countries
  const finalCountries = [...new Set([...mustIncludeCountries, ...qualifyingCountries])];

  // Apply cap if specified
  if (config.countries_cap) {
    if (mustIncludeCountries.length >= config.countries_cap) {
      return mustIncludeCountries;
    }

    const cappedFinal = finalCountries.slice(0, config.countries_cap);
    return cappedFinal;
  }
  return finalCountries;
};
