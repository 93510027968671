import moment from 'moment';

const evaluateFieldConditions = (conditions, values, context = {}) => {
  if (!conditions) return true;

  const { parentField, arrayIndex = 0, fields } = context;

  const evaluateOperation = (conditionValue, operation, targetValue) => {
    // Special cases for existence checks
    if (operation === 'exists') {
      return conditionValue !== undefined && conditionValue !== null;
    }
    if (operation === 'not_exists') {
      return conditionValue === undefined || conditionValue === null;
    }

    // For all other operations, return false if value doesn't exist
    if (conditionValue === undefined || conditionValue === null) {
      return false;
    }

    // Convert boolean strings to actual booleans for comparison
    const normalizeValue = value => {
      if (typeof value === 'string') {
        if (value.toLowerCase() === 'true') return true;
        if (value.toLowerCase() === 'false') return false;
        if (!isNaN(value)) return parseFloat(value);
      }
      return value;
    };

    const normalizedConditionValue = normalizeValue(conditionValue);
    const normalizedTargetValue = normalizeValue(targetValue);

    switch (operation) {
      case 'date_before':
      case 'date_after':
      case 'date_same_or_before':
      case 'date_same_or_after': {
        const fieldDate = moment(conditionValue, 'DD/MM/YYYY');
        const compareDate = /^[+-]\d+$/.test(targetValue)
          ? moment().add(parseInt(targetValue), 'months')
          : moment(targetValue, 'DD/MM/YYYY');

        if (!fieldDate.isValid() || !compareDate.isValid()) {
          return false;
        }

        switch (operation) {
          case 'date_before':
            return fieldDate.isBefore(compareDate);
          case 'date_after':
            return fieldDate.isAfter(compareDate);
          case 'date_same_or_before':
            return fieldDate.isSameOrBefore(compareDate);
          case 'date_same_or_after':
            return fieldDate.isSameOrAfter(compareDate);
        }
      }
      case 'is':
        return normalizedConditionValue === normalizedTargetValue;
      case 'is_not':
        return normalizedConditionValue !== normalizedTargetValue;
      case 'in':
        return targetValue ? targetValue.includes(conditionValue) : true;
      case 'not_in':
        return targetValue ? !targetValue.includes(conditionValue) : true;
      case 'contains':
        return conditionValue ? conditionValue.includes(targetValue) : true;
      case 'not_contains':
        return conditionValue ? !conditionValue.includes(targetValue) : true;
      case 'not_empty':
        if (Array.isArray(conditionValue)) {
          return conditionValue.length > 0;
        }
        return true;
      case 'is_empty':
        if (Array.isArray(conditionValue)) {
          return conditionValue.length === 0;
        }
        return true;
      case 'less_than':
        return (
          typeof normalizedConditionValue === 'number' &&
          typeof normalizedTargetValue === 'number' &&
          normalizedConditionValue < normalizedTargetValue
        );
      case 'greater_than':
        return (
          typeof normalizedConditionValue === 'number' &&
          typeof normalizedTargetValue === 'number' &&
          normalizedConditionValue > normalizedTargetValue
        );
      default:
        return true;
    }
  };

  const evaluateCondition = condition => {
    if (!condition) return true;
    // If this is a nested AND/OR condition
    if (condition.op === 'and' || condition.op === 'or') {
      return evaluateLogicalGroup(condition);
    }

    // Regular condition evaluation
    const { op, code, value, field } = condition;
    if (!op || !code) return true;

    // Special case for array index conditions
    if (code === 'array_index') {
      return evaluateOperation(arrayIndex, op, value);
    }

    // For checking a field property (like 'type'),
    if (field && field !== 'value') {
      // Find the field definition in details.fields
      const fieldDefinition = fields.find(f => f.code === code);

      if (fieldDefinition) {
        const fieldPropertyValue = fieldDefinition[field];
        return evaluateOperation(fieldPropertyValue, op, value);
      }
      return false;
    }

    // Otherwise, get the field value from form values
    const fieldValue =
      parentField && Array.isArray(values[parentField.code])
        ? (values[parentField.code][arrayIndex]?.[code] ?? values[code])
        : values[code];

    return evaluateOperation(fieldValue, op, value);
  };

  const evaluateLogicalGroup = group => {
    if (!group.conditions?.length) return true;

    const results = group.conditions.map(condition => evaluateCondition(condition));
    return group.op === 'and' ? results.every(Boolean) : results.some(Boolean);
  };

  // Handle array of conditions (treat as AND)
  if (Array.isArray(conditions)) {
    return conditions.every(condition => evaluateCondition(condition));
  }

  // Handle single condition or logical group
  return evaluateCondition(conditions);
};

export default evaluateFieldConditions;
