import { Children, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import FeatherIcon from 'feather-icons-react';
import { useClickOutside } from 'hooks/click-outside';
import PropTypes from 'prop-types';

import styles from './MultipleSelect.module.scss';

const SelectContext = createContext(null);

const useSelectContext = () => {
  const context = useContext(SelectContext);
  if (!context)
    throw new Error(
      `MultipleSelect compound components cannot be rendered outside the MultipleSelect component`,
    );
  return context;
};

export const MultipleSelect = ({
  value = [],
  placeholder = '',
  minSelect,
  maxSelect,
  useSearch = false,
  hideLabel = false,
  disabled = false,
  children,
  className,
  onChange,
  error,
  noErrorMessage,
}) => {
  const wrapperRef = useRef(null);

  const [showMenu, setShowMenu] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [optionsMap, setOptionsMap] = useState(new Map());
  const [validationError, setValidationError] = useState('');

  useClickOutside(wrapperRef, () => setShowMenu(false));

  useEffect(() => {
    const newOptionsMap = new Map();
    Children.forEach(children, child => {
      if (!child.props) return;
      newOptionsMap.set(child.props.value, {
        label: child.props.children,
        value: child.props.value,
      });
    });
    setOptionsMap(newOptionsMap);
  }, [children]);

  const selectedValues = useMemo(() => {
    if (!value) return [];
    return value;
  }, [value]);

  const selectedOptions = useMemo(
    () => selectedValues.map(val => optionsMap.get(val)).filter(Boolean),
    [selectedValues, optionsMap],
  );

  const toggleOption = option => {
    const isSelected = selectedValues.includes(option.value);
    const newSelectedValues = isSelected
      ? selectedValues.filter(v => v !== option.value)
      : [...selectedValues, option.value];

    if (isSelected && minSelect && newSelectedValues.length < minSelect) {
      setValidationError(`Please select at least ${minSelect} option${minSelect === 1 ? '' : 's'}`);
      return;
    }

    if (!isSelected && maxSelect && newSelectedValues.length > maxSelect) {
      setValidationError(
        `Please select no more than ${maxSelect} option${maxSelect === 1 ? '' : 's'}`,
      );
      return;
    }

    setValidationError('');
    onChange(newSelectedValues);
  };

  const contextValue = useMemo(
    () => ({ selectedValues, toggleOption, searchQuery }),
    [selectedValues, searchQuery],
  );

  return (
    <SelectContext.Provider value={contextValue}>
      <div ref={wrapperRef} className={cn(styles.root, { [className]: className })}>
        <div
          className={cn(styles.button, { [styles.disabled]: disabled })}
          onClick={() => {
            if (!disabled) setShowMenu(!showMenu);
          }}>
          <span className="u-text-ellipsis">
            {!hideLabel && selectedOptions.length > 0 ? (
              selectedOptions.map(o => o.label).join(', ')
            ) : (
              <span className={styles.placeholder}>{placeholder}</span>
            )}
          </span>
        </div>
        <div className={cn(styles.menu, { [styles.menuVisible]: showMenu })}>
          {useSearch && (
            <>
              <div className={cn(styles.search)}>
                <FeatherIcon className={styles.icon} icon="search" />
                <input
                  type="text"
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value.toLowerCase())}
                  className={styles.input}
                  placeholder="Search..."
                />
              </div>
            </>
          )}
          <ul>{children}</ul>
        </div>
        {error && !noErrorMessage && <span className={styles.error}>{error}</span>}
        {validationError && <span className={styles.error}>{validationError}</span>}
      </div>
    </SelectContext.Provider>
  );
};

MultipleSelect.propTypes = {
  value: PropTypes.array,
  placeholder: PropTypes.string,
  minSelect: PropTypes.number,
  maxSelect: PropTypes.number,
  useSearch: PropTypes.bool,
  hideLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  noErrorMessage: PropTypes.bool,
};

const Item = ({ value, children, description, active = false }) => {
  const { selectedValues, toggleOption, searchQuery } = useSelectContext();
  const [filtered, setFiltered] = useState(false);

  useEffect(() => {
    setFiltered(children.toLowerCase().indexOf(searchQuery) < 0);
  }, [searchQuery]);

  const handleSelected = () => {
    toggleOption({ label: children, value: value });
  };

  useEffect(() => {
    if (active !== undefined) {
      const isSelected = selectedValues.includes(value);
      if ((active && !isSelected) || (!active && isSelected)) {
        handleSelected();
      }
    }
  }, [active]);

  return (
    <li className={cn(styles.item, { [styles.itemHidden]: filtered })} onClick={handleSelected}>
      <img
        src={`assets/images/icons/${selectedValues.includes(value) ? 'checkbox_checked' : 'checkbox_empty'}.svg`}
        className="u-margin-right--small"
      />
      {children}
      <p className={styles.itemDescription}>{description}</p>
    </li>
  );
};

Item.propTypes = {
  value: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  active: PropTypes.bool,
};

MultipleSelect.Item = Item;
