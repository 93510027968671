import evaluateFieldConditions from '../evaluate-field-conditions';

const clearAndUnregister = (code, field, values, unregister) => {
  switch (field.type) {
    case 'array':
    case 'dynamic_array':
      const arrayLength =
        field.type === 'dynamic_array'
          ? values[code]?.length || 1 // Use actual length for dynamic arrays
          : field.max || 1; // Use max length for static arrays

      for (let i = 0; i < arrayLength; i++) {
        if (field.sub_fields) {
          field.sub_fields.forEach(subField => {
            const subFieldCode = `${code}.${i}.${subField.code}`;
            clearAndUnregister(subFieldCode, subField, values, unregister);
          });
        }
      }
      break;
    case 'object':
      field.sub_fields.forEach(subField => {
        clearAndUnregister(subField.code, subField, values, unregister);
      });
      break;
    default:
      values[code] = '';
      unregister(code);
      break;
  }
};

const clearHiddenValues = (
  field,
  values,
  fields,
  unregister = null,
  parentField = null,
  arrayIndex = 0,
) => {
  const code =
    parentField?.type === 'array' || parentField?.type === 'dynamic_array'
      ? `${parentField.code}.${arrayIndex}.${field.code}`
      : field.code;

  let conditionsMet = true;
  if (field.conditions) {
    conditionsMet = evaluateFieldConditions(field.conditions, values, {
      parentField,
      arrayIndex,
      fields,
    });
  }

  // If conditions not met or field is hidden
  if (!conditionsMet) {
    clearAndUnregister(code, field, values, unregister);
    return;
  }

  switch (field.type) {
    case 'array':
    case 'dynamic_array':
      const arrayLength =
        field.type === 'dynamic_array'
          ? values[code]?.length || 1 // Use actual length for dynamic arrays
          : field.max || 1; // Use max length for static arrays

      for (let i = 0; i < arrayLength; i++) {
        if (field.sub_fields) {
          field.sub_fields.forEach(subField => {
            clearHiddenValues(subField, values, fields, unregister, field, i);
          });
        }
      }
      break;
    case 'object':
      field.sub_fields.forEach(subField => {
        clearHiddenValues(subField, values, fields, unregister);
      });
      break;
    default:
      break;
  }

  // Only process sub-fields if parent is visible
  if (field.sub_fields) {
    console.log('Processing sub-fields for visible field:', code);

    if (field.type === 'array' || field.type === 'dynamic_array') {
      // Get the appropriate array length
      const arrayLength =
        field.type === 'dynamic_array'
          ? values[field.code]?.length || 1 // Use actual length for dynamic arrays
          : field.max || 1; // Use max length for static arrays

      // Process each array index
      for (let i = 0; i < arrayLength; i++) {
        field.sub_fields.forEach(subField => {
          clearHiddenValues(subField, values, fields, unregister, field, i);
        });
      }
    } else {
      // For non-array fields, just process sub-fields normally
      field.sub_fields.forEach(subField => {
        clearHiddenValues(subField, values, fields, unregister, field, arrayIndex);
      });
    }
  }
};

export default clearHiddenValues;
