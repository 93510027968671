import { Children, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import FeatherIcon from 'feather-icons-react';
import { useClickOutside } from 'hooks/click-outside';
import { useCombinedRefs } from 'hooks/combined-refs';
import PropTypes from 'prop-types';
import { ChangeEvent } from 'utils/form-utils';

import styles from './Select.module.scss';

const SelectContext = createContext(null);

const useSelectContext = () => {
  const context = useContext(SelectContext);
  if (!context)
    throw new Error(`Select compound components cannot be rendered outside the Select component`);
  return context;
};

export const Select = ({
  name,
  value,
  placeholder = '',
  defaultValue,
  useDefault = false,
  useSearch = false,
  validators = {},
  register = (...args) => ({ ref: () => {} }),
  disabled,
  children,
  className,
  inputProps = {},
  error,
  noErrorMessage,
}) => {
  // Be able to use ref with register
  const { ref, ...registerProps } = register(name, validators);
  const inputRef = useRef(null);
  const combinedRef = useCombinedRefs(ref, inputRef);

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => setShowMenu(false));

  const [options, setOptions] = useState([]);
  const [optionLabel, setOptionLabel] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const [showMenu, setShowMenu] = useState(false);

  // Store options
  useEffect(() => {
    setOptions(
      Children.toArray(children).map(child => ({
        label: child.props.children,
        value: child.props.value,
      })),
    );
  }, [children]);

  // Set label
  useEffect(() => {
    if (options.length < 1) setOptionLabel('');

    const option = options.find(option => option.value == value);
    setOptionLabel(option?.label);
  }, [value, options]);

  // Set default
  useEffect(() => {
    if (!options.length) return;

    // If we have a specific defaultValue, use it
    if (defaultValue && !value) {
      setOption(defaultValue);
      return;
    }

    // Otherwise handle useDefault logic
    if (useDefault && !value && options.length > 0) {
      if (options[0].value === value) return;
      setOption(options[0].value);
    }
  }, [value, defaultValue, useDefault, options]);

  // Set option
  const setOption = value => {
    setShowMenu(false);
    const onChange = registerProps?.onChange || inputProps?.onChange;
    onChange(ChangeEvent(name, value));
  };

  // Expose to context
  const contextValue = useMemo(() => ({ setOption, searchQuery }), [options, searchQuery]);

  // Search query change
  const handleQueryChange = e => setSearchQuery(e.target.value.toLowerCase());

  // Empty search
  useEffect(() => {
    if (!showMenu) setSearchQuery('');
  }, [showMenu]);

  return (
    <SelectContext.Provider value={contextValue}>
      <div ref={wrapperRef} className={cn(styles.root, { [className]: className })}>
        <input
          name={name}
          ref={combinedRef}
          value={value || ''} // Ensure value is controlled
          {...registerProps}
          {...inputProps}
        />
        <div
          className={cn(styles.button, { [styles.disabled]: disabled })}
          onClick={() => {
            if (!disabled) setShowMenu(!showMenu);
          }}>
          <span className="u-text-ellipsis">
            {optionLabel === null || optionLabel === undefined ? (
              <span className={styles.placeholder}>{placeholder}</span>
            ) : (
              optionLabel
            )}
          </span>
        </div>
        <div className={cn(styles.menu, { [styles.menuVisible]: showMenu })}>
          {useSearch && (
            <>
              <div className={cn(styles.search)}>
                <FeatherIcon className={styles.icon} icon="search" />
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleQueryChange}
                  className={styles.input}
                  placeholder="Search..."
                />
              </div>
            </>
          )}
          <ul>{children}</ul>
        </div>
        {error && !noErrorMessage && <span className={styles.error}>{error}</span>}
      </div>
    </SelectContext.Provider>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.any,
  useDefault: PropTypes.bool,
  useSearch: PropTypes.bool,
  validators: PropTypes.object,
  register: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  inputProps: PropTypes.object,
  error: PropTypes.string,
  noErrorMessage: PropTypes.bool,
};

const Item = ({ value, children, className, description }) => {
  const { setOption, searchQuery } = useSelectContext();

  const [filtered, setFiltered] = useState(false);

  const handleSelected = () => setOption(value);

  useEffect(() => {
    setFiltered(children.toString().toLowerCase().indexOf(searchQuery) < 0);
  }, [searchQuery]);

  return (
    <li
      className={cn(styles.item, { [styles.itemHidden]: filtered, [className]: className })}
      onClick={handleSelected}>
      {children}
      {description && <p className={styles.itemDescription}>{description}</p>}
    </li>
  );
};

Item.propTypes = {
  value: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.string,
};

Select.Item = Item;
