// ============================================================================
// Core Utilities
// ============================================================================

// Creates variations of a full name from its components
export const createFullNameVariations = (firstName, middleNames, lastName) => {
  const variations = [];

  // Full name with middle names
  if (firstName && lastName) {
    const fullName = [firstName, middleNames, lastName].filter(Boolean).join(' ');
    variations.push(fullName);

    // First + last name (if different from full name)
    const firstLast = `${firstName} ${lastName}`;
    if (firstLast !== fullName) {
      variations.push(firstLast);
    }
  }

  return variations;
};

// Format names for dropdown options
const formatForDropdown = names =>
  names.map(name => ({
    name,
    value: name,
  }));

// ============================================================================
// Internal Processing Functions
// ============================================================================

// Get current legal names based on form values
const getCurrentNames = formValues => {
  if (!formValues) return [];

  // If single_name_only is true, only use single_name if it exists
  if (formValues.single_name_only) {
    return formValues.single_name ? [formValues.single_name] : [];
  }

  // Otherwise use first/middle/last name fields
  return createFullNameVariations(
    formValues.first_name,
    formValues.middle_names,
    formValues.last_name,
  );
};

// Get all other names (excluding current legal name)
const getOtherNames = formValues => {
  if (!formValues) return [];

  const nameArrays = {
    firstNames: [],
    firstMiddleNames: [],
    lastNames: [],
    fullNames: [],
  };

  const addUnique = (array, value) => {
    if (value && !array.includes(value)) array.push(value);
  };

  // Add current first name for combinations (only if not using single_name_only)
  if (formValues.first_name) {
    addUnique(nameArrays.firstNames, formValues.first_name);

    if (formValues.middle_names) {
      const firstMiddle = `${formValues.first_name} ${formValues.middle_names}`;
      addUnique(nameArrays.firstMiddleNames, firstMiddle);
    }
  }

  // Process other names array
  if (Array.isArray(formValues.other_names)) {
    formValues.other_names.forEach(otherName => {
      if (!otherName?.other_name_category) return;

      const category = otherName.other_name_category;

      switch (category) {
        case 'maiden':
        case 'previous_married_name':
          if (otherName.other_last_name) {
            addUnique(nameArrays.lastNames, otherName.other_last_name);
          }
          break;

        case 'legal_name_change':
        case 'alias':
          if (otherName.other_single_name) {
            addUnique(nameArrays.fullNames, otherName.other_single_name);
          } else {
            if (otherName.other_first_name) {
              addUnique(nameArrays.firstNames, otherName.other_first_name);

              if (otherName.other_middle_names) {
                const firstMiddle = `${otherName.other_first_name} ${otherName.other_middle_names}`;
                addUnique(nameArrays.firstMiddleNames, firstMiddle);
              }

              // Use createFullNameVariations for full name variations
              const variations = createFullNameVariations(
                otherName.other_first_name,
                otherName.other_middle_names,
                otherName.other_last_name,
              );
              variations.forEach(variation => addUnique(nameArrays.fullNames, variation));
            } else if (otherName.other_last_name) {
              addUnique(nameArrays.lastNames, otherName.other_last_name);
            }
          }
          break;
      }
    });
  }

  // Generate combinations with maiden/previous names
  nameArrays.lastNames.forEach(lastName => {
    nameArrays.firstNames.forEach(firstName => {
      addUnique(nameArrays.fullNames, `${firstName} ${lastName}`);
    });

    nameArrays.firstMiddleNames.forEach(firstMiddle => {
      addUnique(nameArrays.fullNames, `${firstMiddle} ${lastName}`);
    });
  });

  return nameArrays.fullNames;
};

// ============================================================================
// Public API Functions
// ============================================================================

export const getAllNames = formValues => {
  if (!formValues) return [];
  return [...getCurrentNames(formValues), ...getOtherNames(formValues)];
};

// Get other names formatted for dropdown (used by processDynamicContent)
export const buildOtherNameVersions = formValues => {
  if (!formValues) return [];
  return formatForDropdown(getOtherNames(formValues));
};
