import { Input, InputGroup, Select } from '..';

import { useEffect, useState } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import {
  ADDRESS_FIELDS,
  ADDRESS_STRUCTURE,
  POSTAL_CODE_FORMATS,
  VALIDATION_MESSAGES,
} from '../../../constants/address-structure';
import { countries } from '../../../constants/countries';
import { getFullCountryName } from '../../../helpers/countries';
import DisplayBox from '../../DisplayBox/DisplayBox';
import { GoogleAddressInput } from '../GoogleAddressInput/GoogleAddressInput';
import styles from './Address.module.scss';

export const Address = ({
  name,
  value = {},
  title,
  description,
  tooltip,
  autoComplete,
  defaultValue,
  required = false,
  register,
  setValue,
  error,
  noErrorMessage = false,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [showAddressBox, setShowAddressBox] = useState(true);

  const selectedCountry = value?.country || getFullCountryName(defaultValue);
  const structure = ADDRESS_STRUCTURE[selectedCountry] || ADDRESS_STRUCTURE.default;

  const googleFieldValidators = {
    required: {
      value: !isManualEntry,
      message: 'Address is required',
    },
  };

  useEffect(() => {
    if (!error || Object.keys(error).length === 0) {
      setErrorMessage(null);
      return;
    }

    // Get all errors
    const allErrors = Object.values(error);

    console.log('allErrors', allErrors);

    // First check for required errors
    const requiredErrors = allErrors.filter(err => err?.type === 'required');
    if (requiredErrors.length > 0) {
      setErrorMessage(
        requiredErrors.length > 1
          ? 'Please complete all required address fields'
          : requiredErrors[0].message,
      );
      return;
    }

    // If no required errors, check for other error types
    const otherErrors = allErrors.filter(err => err?.message);
    if (otherErrors.length > 0) {
      setErrorMessage(
        otherErrors.length > 1
          ? 'Please fix the errors in the address fields'
          : otherErrors[0].message,
      );
      return;
    }

    setErrorMessage(null);
  }, [error]);

  useEffect(() => {
    // Check if we have any address field values
    const hasValues = ADDRESS_FIELDS.some(field => value[field.fieldName]);

    if (hasValues) {
      // If there's an google_address value, keep manual entry off
      // Otherwise, set manual entry to true
      setIsManualEntry(!value.google_address);
      setShowAddressBox(true);

      // If there's a country value, ensure it's in the correct format
      if (value.country) {
        const formattedCountry = getFullCountryName(value.country);
        if (formattedCountry !== value.country) {
          setValue(`${name}.country`, formattedCountry, { shouldValidate: true });
        }
      }
      // If no country is set but we have a default value, set it
      else if (defaultValue) {
        const formattedCountry = getFullCountryName(defaultValue);
        setValue(`${name}.country`, formattedCountry, { shouldValidate: true });
      }
    }
  }, [value, setValue, name, defaultValue]);

  const getFieldValidators = fieldName => {
    const fieldValidators = {};

    // Add required validation if field is in structure and not suburb
    if (structure[fieldName] && fieldName !== 'suburb' && required) {
      fieldValidators.required = {
        value: true,
        message: `${structure[fieldName]} is required`,
      };
    }

    // Add postal code validation if applicable
    if (fieldName === 'postal_code' && structure.postal_code) {
      const postalFormat = POSTAL_CODE_FORMATS[selectedCountry] || POSTAL_CODE_FORMATS.default;
      fieldValidators.pattern = {
        value: new RegExp(postalFormat),
        message:
          VALIDATION_MESSAGES.postal_code[selectedCountry] ||
          VALIDATION_MESSAGES.postal_code.default,
      };
    }

    return fieldValidators;
  };

  const handleManualEntry = e => {
    setShowAddressBox(false);
    setValue(`${name}.google_address`, '', { shouldValidate: false });
    clearAddress();
    setIsManualEntry(e.target.checked);
    setErrorMessage(null);
  };

  const handleAddressSelect = placeDetails => {
    // Clear existing address fields first
    clearAddress();

    const findComponent = componentTypes => {
      if (componentTypes.includes('street_number')) {
        const premise =
          placeDetails?.addressComponents?.find(component => component.types.includes('premise'))
            ?.longText || '';

        const subpremise =
          placeDetails?.addressComponents?.find(component => component.types.includes('subpremise'))
            ?.longText || '';

        // Special handling for street_address to combine multiple components
        const streetNumber =
          placeDetails?.addressComponents?.find(component =>
            component.types.includes('street_number'),
          )?.longText || '';

        const route =
          placeDetails?.addressComponents?.find(component => component.types.includes('route'))
            ?.longText || '';

        // Combine components, filtering out empty strings and joining with appropriate separators
        const parts = [streetNumber, route, premise, subpremise]
          .filter(Boolean) // Remove empty strings
          .join(' ');
        return parts;
      }

      // Handle other fields normally
      for (const type of componentTypes) {
        const component = placeDetails?.addressComponents?.find(component =>
          component.types.includes(type),
        );
        if (component?.longText) {
          return component.longText;
        }
      }
      return '';
    };

    // Update each address field using the googleComponents mapping from ADDRESS_FIELDS
    ADDRESS_FIELDS.forEach(field => {
      if (field.googleComponents) {
        const value = findComponent(field.googleComponents);
        setValue(`${name}.${field.fieldName}`, value, { shouldValidate: true });
      } else {
        setValue(`${name}.${field.fieldName}`, '', { shouldValidate: true });
      }
    });

    // Handle country separately since it needs special formatting
    const countryCode = findComponent(['country']);
    if (countryCode) {
      const formattedCountry = getFullCountryName(countryCode);
      setValue(`${name}.country`, formattedCountry, { shouldValidate: true });
    }

    setIsManualEntry(false);
  };

  const clearAddress = () => {
    ADDRESS_FIELDS.forEach(field => {
      setValue(`${name}.${field.fieldName}`, '', { shouldValidate: true });
    });

    const formattedCountry = getFullCountryName(defaultValue);
    setValue(`${name}.country`, formattedCountry, { shouldValidate: true });
  };

  const hasAddressValues = () => {
    return isManualEntry || value.google_address;
  };

  const getFormattedAddress = () => {
    const addressParts = ADDRESS_FIELDS.map(field => value[field.fieldName]).filter(Boolean);
    const country = countries.find(c => c.value === value.country)?.name;
    if (country) addressParts.push(country);
    return addressParts;
  };

  return (
    <InputGroup title={title} description={description} tooltip={tooltip} error={errorMessage}>
      <div className={cn(styles.addressContainer)}>
        <GoogleAddressInput
          name={`${name}.google_address`}
          register={register}
          setValue={setValue}
          onManualEntry={handleManualEntry}
          onAddressSelect={handleAddressSelect}
          country={defaultValue}
          hasManualEntry={isManualEntry}
          validators={googleFieldValidators}
        />

        {isManualEntry && (
          <>
            {ADDRESS_FIELDS.some(field => value[field.fieldName]) && showAddressBox ? (
              <DisplayBox show={true} cancelable onCancel={() => setShowAddressBox(false)}>
                <div className="d-flex flex-column u-align-items-center">
                  {getFormattedAddress().map((part, index) => (
                    <span key={index} className="secondary-text t-body">
                      {part}
                    </span>
                  ))}
                </div>
              </DisplayBox>
            ) : (
              <div className={styles.manualFields}>
                {ADDRESS_FIELDS.map(field => {
                  const isFieldInStructure =
                    structure[field.fieldName] && structure[field.fieldName] !== null;

                  return isFieldInStructure ? (
                    <Input
                      key={field.fieldName}
                      name={`${name}.${field.fieldName}`}
                      type="text"
                      autoComplete={autoComplete === 'current' ? field.autocomplete : undefined}
                      placeholder={structure[field.fieldName]}
                      maxLength={field.maxLength}
                      validators={isManualEntry ? getFieldValidators(field.fieldName) : {}}
                      register={register}
                      noErrorMessage={true}
                      className="u-margin-bottom--small"
                    />
                  ) : null;
                })}
                <Select
                  name={`${name}.country`}
                  value={selectedCountry}
                  placeholder="Select Country..."
                  defaultValue={getFullCountryName(defaultValue)}
                  useSearch
                  validators={required && isManualEntry ? { required: 'Country is required' } : {}}
                  register={register}
                  error={error?.country?.message}
                  noErrorMessage={true}>
                  {countries.map(country => (
                    <Select.Item key={country.value} value={country.value}>
                      {country.name}
                    </Select.Item>
                  ))}
                </Select>
              </div>
            )}
          </>
        )}
        <DisplayBox show={!hasAddressValues()}>
          <div className="d-flex flex-column u-align-items-center">
            <span className="primary-text title-5 text-center">No address selected</span>
            <span className="secondary-text t-body text-center">
              Search for, or enter an address manually
            </span>
          </div>
        </DisplayBox>
      </div>
      {errorMessage && !noErrorMessage && <span className={styles.error}>{errorMessage}</span>}
    </InputGroup>
  );
};

Address.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  tooltip: PropTypes.string,
  autoComplete: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  error: PropTypes.object,
  noErrorMessage: PropTypes.bool,
};
