// Base ID types
const baseIdTypes = {
  DRIVER_LICENSE: { name: 'Driver License', value: 'driver_license' },
  NZ_DRIVER_LICENCE: { name: 'NZ Driver Licence', value: 'driver_license:NZ' },
  AU_DRIVER_LICENCE: { name: 'AU Driver Licence', value: 'driver_license:AU' },
  US_DRIVER_LICENSE: { name: 'US Driver License', value: 'driver_license:US' },

  TEMPORARY_DRIVER_LICENSE: { name: 'Temporary Driver License', value: 'temporary_driver_license' },
  NZ_TEMPORARY_DRIVER_LICENCE: {
    name: 'NZ Temporary Driver License',
    value: 'temporary_driver_license:NZ',
  },
  AU_TEMPORARY_DRIVER_LICENCE: {
    name: 'AU Temporary Driver License',
    value: 'temporary_driver_license:AU',
  },

  PASSPORT: { name: 'Passport', value: 'passport' },
  FOREIGN_PASSPORT: { name: 'Foreign Passport', value: 'passport' },
  NZ_PASSPORT: { name: 'NZ Passport', value: 'passport:NZ' },
  AU_PASSPORT: { name: 'AU Passport', value: 'passport:AU' },

  FIREARMS_LICENSE: { name: 'Firearms License', value: 'firearms_license' },
  NZ_FIREARMS_LICENCE: { name: 'NZ Firearms Licence', value: 'firearms_license:NZ' },

  TERTIARY_STUDENT_ID: { name: 'Tertiary Student ID', value: 'tertiary_student_id' },
  NZ_TERTIARY_STUDENT_ID: { name: 'NZ Tertiary Student ID', value: 'tertiary_student_id:NZ' },
  AU_TERTIARY_STUDENT_ID: { name: 'AU Tertiary Student ID', value: 'tertiary_student_id:AU' },

  AGE_VERIFICATION_CARD: { name: 'Age Verification Card', value: 'age_verification_card' },
  NZ_18_PLUS_CARD: { name: '18+ Card', value: 'age_verification_card:NZ' },
  AU_BIRTH_CARD: { name: 'Birth Card', value: 'age_verification_card:AU' },

  SENIOR_CITIZEN_CARD: { name: 'Senior Citizen Card', value: 'senior_citizen_card' },
  NZ_SUPER_GOLD_CARD: { name: 'SuperGold Card', value: 'senior_citizen_card:NZ' },

  SOCIAL_SERVICES_CARD: { name: 'Social Services Card', value: 'social_services_card' },
  NZ_COMMUNITY_SERVICES_CARD: { name: 'Community Services Card', value: 'social_services_card:NZ' },
  AU_CENTRELINK_CARD: { name: 'CentreLink Card', value: 'social_services_card:AU' },
  AU_MEDICARE_CARD: { name: 'Medicare Card', value: 'medicare_card:AU' },

  GOVERNMENT_EMPLOYMENT_ID: { name: 'Government Employment ID', value: 'government_employment_id' },
  NZ_GOVERNMENT_EMPLOYMENT_ID: {
    name: 'NZ Government Employment ID',
    value: 'government_employment_id:NZ',
  },
  AU_GOVERNMENT_EMPLOYMENT_ID: {
    name: 'AU Government Employment ID',
    value: 'government_employment_id:AU',
  },
  NZ_DEFENCE_FORCE_ID: { name: 'NZ Defence Force ID', value: 'defense_force_id:NZ' },
  AU_DEFENCE_FORCE_ID: { name: 'AU Defence Force ID', value: 'defense_force_id:AU' },
  AU_DVA_CARD: { name: 'Department of Veterans Affairs Card', value: 'veterans_card:AU' },

  BIRTH_CERTIFICATE: { name: 'Birth Certificate', value: 'birth_certificate' },
  NZ_BIRTH_CERTIFICATE: { name: 'NZ Birth Certificate', value: 'birth_certificate:NZ' },
  AU_BIRTH_CERTIFICATE: { name: 'AU Birth Certificate', value: 'birth_certificate:AU' },

  BIRTH_CERTIFICATE_EXTRACT: {
    name: 'Birth Certificate Extract',
    value: 'birth_certificate_extract',
  },
  NZ_BIRTH_CERTIFICATE_EXTRACT: {
    name: 'NZ Birth Certificate Extract',
    value: 'birth_certificate_extract:NZ',
  },
  AU_BIRTH_CERTIFICATE_EXTRACT: {
    name: 'AU Birth Certificate Extract',
    value: 'birth_certificate_extract:AU',
  },

  MARRIAGE_CERTIFICATE: { name: 'Marriage Certificate', value: 'marriage_certificate' },
  NZ_MARRIAGE_CERTIFICATE: { name: 'NZ Marriage Certificate', value: 'marriage_certificate:NZ' },
  AU_MARRIAGE_CERTIFICATE: { name: 'AU Marriage Certificate', value: 'marriage_certificate:AU' },

  DIVORCE_DOCUMENTATION: { name: 'Divorce Documentation', value: 'divorce_documentation' },
  NZ_DIVORCE_DOCUMENTATION: { name: 'NZ Divorce Documentation', value: 'divorce_documentation:NZ' },
  AU_DIVORCE_DOCUMENTATION: { name: 'AU Divorce Documentation', value: 'divorce_documentation:AU' },

  CITIZENSHIP_CERTIFICATE: { name: 'Citizenship Certificate', value: 'citizenship_certificate' },
  NZ_CITIZENSHIP_CERTIFICATE: {
    name: 'NZ Citizenship Certificate',
    value: 'citizenship_certificate:NZ',
  },
  AU_CITIZENSHIP_CERTIFICATE: {
    name: 'AU Citizenship Certificate',
    value: 'citizenship_certificate:AU',
  },

  CERTIFICATE_OF_IDENTITY: { name: 'Certificate of Identity', value: 'certificate_of_identity' },
  NZ_CERTIFICATE_OF_IDENTITY: {
    name: 'NZ Certificate of Identity',
    value: 'certificate_of_identity:NZ',
  },
  AU_CERTIFICATE_OF_IDENTITY: {
    name: 'AU Certificate of Identity',
    value: 'certificate_of_identity:AU',
  },

  NAME_CHANGE_DOCUMENTATION: {
    name: 'Name Change Documentation',
    value: 'name_change_documentation',
  },
  AU_NAME_CHANGE_DOCUMENTATION: {
    name: 'AU Name Change Documentation',
    value: 'name_change_documentation:AU',
  },
  NZ_NAME_CHANGE_DOCUMENTATION: {
    name: 'NZ Name Change Documentation',
    value: 'name_change_documentation:NZ',
  },

  UTILITY_BILL: { name: 'Utility Bill', value: 'utility_bill' },
  NZ_UTILITY_BILL: { name: 'NZ Utility Bill', value: 'utility_bill:NZ' },
  AU_UTILITY_BILL: { name: 'AU Utility Bill', value: 'utility_bill:AU' },

  RATES_NOTICE: { name: 'Rates Notice', value: 'rates_notice' },
  NZ_RATES_NOTICE: { name: 'NZ Rates Notice', value: 'rates_notice:NZ' },
  AU_RATES_NOTICE: { name: 'AU Rates Notice', value: 'rates_notice:AU' },

  BANK_STATEMENT: { name: 'Bank Statement', value: 'bank_statement' },
  NZ_BANK_STATEMENT: { name: 'NZ Bank Statement', value: 'bank_statement:NZ' },
  AU_BANK_STATEMENT: { name: 'AU Bank Statement', value: 'bank_statement:AU' },

  PROPERTY_LEASE_AGREEMENT: { name: 'Property Lease Agreement', value: 'lease_agreement' },
  NZ_PROPERTY_LEASE_AGREEMENT: { name: 'NZ Property Lease Agreement', value: 'lease_agreement:NZ' },
  AU_PROPERTY_LEASE_AGREEMENT: { name: 'AU Property Lease Agreement', value: 'lease_agreement:AU' },

  MORTGAGE_DOCUMENTS: { name: 'Mortgage Documents', value: 'mortgage_documents' },
  NZ_MORTGAGE_DOCUMENTS: { name: 'NZ Mortgage Documents', value: 'mortgage_documents:NZ' },
  AU_MORTGAGE_DOCUMENTS: { name: 'AU Mortgage Documents', value: 'mortgage_documents:AU' },

  TAXATION_ASSESSMENT_NOTICE: { name: 'Taxation Assessment Notice', value: 'tax_notice' },
  NZ_TAXATION_ASSESSMENT_NOTICE: { name: 'NZ IRD Assessment Notice', value: 'tax_notice:NZ' },
  AU_TAXATION_ASSESSMENT_NOTICE: { name: 'AU Tax Assessment Notice', value: 'tax_notice:AU' },
};

// ID type sets
const idTypeSets = {
  PHOTO_IDS: [
    baseIdTypes.PASSPORT,
    baseIdTypes.DRIVER_LICENSE,
    baseIdTypes.FIREARMS_LICENSE,
    baseIdTypes.TERTIARY_STUDENT_ID,
    baseIdTypes.AGE_VERIFICATION_CARD,
  ],
};

// Export the combined object
export const idTypes = {
  ...baseIdTypes,
  ...idTypeSets,
};
