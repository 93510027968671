import { Alpha3Countries, countries } from '../constants/countries';

export const getFullCountryName = value => {
  if (!value) return null;

  // Check common 2-letter codes first
  switch (value) {
    case 'NZ':
      return 'New Zealand';
    case 'AU':
      return 'Australia';
    case 'US':
      return 'United States of America';
  }

  // Check if it's already a full country name
  const isFullName = countries.some(country => country.value === value);
  if (isFullName) return value;

  // Check if it's an alpha3 code
  const alpha3Country = Alpha3Countries.find(country => country.code === value);
  if (alpha3Country) {
    const matchingCountry = countries.find(country => country.name === alpha3Country.name);
    return matchingCountry?.value || null;
  }

  return null;
};
